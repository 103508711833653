import React, { useEffect } from 'react'
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import "./mirenjohjet.scss"
import mirenjohjetDb from "../../DB/mirenjohjet.json"

import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';

const Mirenjohjet = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    const images = mirenjohjetDb
    // console.log('imagesMirenjohje', images)

    return (
        <div className="mirenjohjet_layout">
            <div className="container">
                <p className='mirenjohjet_title'>{Translations(props?.langData, props?.selected_lang, 'Mirenjohjet-tona-Label')}</p>
                <p className='mirenjohjet_text'>{Translations(props?.langData, props?.selected_lang, 'Mirenjohjet-tona-description-Label')}</p>
                <ImageGallery
                    items={images}
                    showPlayButton={false}
                />
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Mirenjohjet);
  
  
// export default Mirenjohjet