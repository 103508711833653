
import React, { useEffect, useState } from 'react'
import './css/commingSoon.scss'
import IMG1 from "../../images/commingSoon/bg.png"
import companyLogo from "../../images/companyLogo.png"

const CommingSoon = () => {
    const [showInput, setShowInput] = useState(false);

    function setShouldLogIn() {
        setShowInput(true)
    }
    const submitLogin = async (e) => {
        e.preventDefault();
        let username = e.target.username.value
        let pass = e.target.password.value
        if (username !== "ADMIN" || pass !== "1234") {
            alert("You do not have access!")
        } else {
            window.location.assign("/")
        }

    }
    return (

        <div className='commingSoonBody'>
            <div className="content col-12">
                <p className="weWillBeOnlineSoon carousel-item active">We will be online soon!</p>
                <img src={IMG1} className="bgImg" />
                <div className="log" />
            </div>
            <footer>
                <div className="footerCenter col-12" >
                    <div className="row">
                        <a className="footerText col-md-3 col-12" href="tel:+38349777075">+ 383 (0) 49 777 075 <p className="vija"> | </p></a>
                        <a className="footerText col-md-3 col-12" href="tel:+38349530414"> + 383 (0) 49 530 414 <p className="vija"> | </p></a>
                        <a className="footerText col-md-3 col-12" href="mailto: shitja@m-technologie.com">shitja@m-technologie.com <p className="vija"> | </p></a>
                        <a className="footerText col-md-3 col-12" href="mailto: info@m-technologie.com">info@m-technologie.com </a>
                    </div>
                </div>
                <img src={companyLogo} className="companyLogoFooter" alt="m-technologie" onClick={setShouldLogIn} />
            </footer>
            {showInput == true ?
                <div className="col-3 d-block">

                    <form onSubmit={submitLogin}>
                        <input type="text" id="username" name='username' />
                        <input type="password" id='password' name='password' />
                        <button type="submit" className='w-50'>Redirect</button>
                    </form>

                </div>
                :
                ""}
        </div>
    )
}
export default CommingSoon;





