import './homepage.scss';
import React, { useEffect, useState } from 'react';
import PVCimg from "../../images/products/M-technologie-PVC-Product-2.png"
import AluminProdukt from "../../images/products/Produktet-e-Aluminit.jpg"
import SpiderGlassIMG from "../../images/products/Spider-Glass.png"
import ZgjedhShqip from "../../images/dashboard-images/PVC-Shqip.png"
import ZgjedhEN from "../../images/dashboard-images/PVC-English.png"
import ZgjedhDE from "../../images/dashboard-images/PVC-German.png"
import imagePrishtina from "../../images/dashboard-images/benefitet.png"
import imagePrishtinaMobile from "../../images/dashboard-images/benefitet_mobile.png"
// import ourPartners from "../../DB/partneret"
import Translations from '../../components/translations/translations';
import Slider from "react-slick";
import { Link } from 'react-router-dom';


// images of the slider with z-index !

import SliderImages from "../../DB/sliderImages.json"

import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';


import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import { param } from 'jquery';
import axios from '../../axios';
import LatestNews from '../../components/latestNews/latestNews';
import HighMenagmentStaff from '../../components/highMenagmentStaff/highMenagmentStaff';

SwiperCore.use([Navigation, Pagination]);

// import required modules
const Homepage = (props) => {
  // let slicedGalley = galleryData.slice(0, 6);
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);



  const [ourStaff, setOurStaff] = useState([])
  console.log("ourStaff", ourStaff)

  function handleGetPartners() {
      axios.get("/api/admin/our_staff/all")
          .then(res => {
              setOurStaff(res.data)
          })
          .catch(err => {
              // toast.error(err)
              console.log('error getting partners ', err)
          })
  }
  useEffect(() => {
      handleGetPartners()
  }, [])


  // useEffect(()=>{
  //   window.onload = function() {
  //     // All static resources, including images, have finished loading
  //     // Disable your loader here
  //   };
  // },[])

  const handleChange = (event) => {
    const index = parseInt(event.target.value);
    setActiveIndex(index);
    swiper.slideTo(index, 300);
    // const { value } = event.target.value;
    // console.log('event.target', event.target.value)
    // // setActiveIndex(value);
    // console.log('swiper',params.onSwiper(value))
    // // params?.onSwiper = setActiveIndex(value)
    // params.onSwiper(value)
    // swiper.slideTo(value, 300, false);
  };

  const params = {
    speed: 500,
    effect: "cards",
    modules: [EffectCards],
    className: "mySwiper",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setActiveIndex(e.activeIndex),
    // onSwiper: (swiper) => setActiveIndex(swiper),
  };
  // Slick Slider Settings
  var slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  var firstSlickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 800,
    cssEase: "linear"
  };
  const style1 = {
    backgroundPosition: "4%",
  }

  const [homepageSliders, setHomepageSliders] = useState([])
  const [slicedGalley, setSlicedGalley] = useState([])
  const [ourPartners, setOurPartners] = useState([])

  const getHomePageSliders = () => {
    axios.get("/api/client/homepage/slider-projects")
      .then((res) => {
        setHomepageSliders(res.data)
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })
  }

  const getSlicedGalley = () => {
    axios.get("/api/client/homepage/latest-projects")
      .then((res) => {
        setSlicedGalley(res.data)
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })
  }
  const getOurPartners = () => {
    axios.get("/api/client/homepage/our-partners")
      .then((res) => {
        setOurPartners(res.data)
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })
  }
  useEffect(() => {
    getHomePageSliders()
    getSlicedGalley()
    getOurPartners()
  }, [])

  const AppApi = `https://api.m-technologie.com/`
  // const { imageIndex } = this.state;
  return (
    <>
      <div className="homepage_layout">
        <div className='opening_parent_slider'>
          <Slider {...firstSlickSettings}>
            {homepageSliders?.map((el, index) =>

              <div key={index}>
                <img src={AppApi + el?.bg_img} className="images_homepage d-block w-100" style={{ objectPosition: "left" }} alt={el?.alt} />
                <div className="container " >
                  <h1 className='ImageDescription col-md-6 col-5 ' data-aos="fade-right" data-aos-duration="1500" dangerouslySetInnerHTML={{ __html: `${el?.title}` }}></h1>
                  <div className="lokacioni" data-aos="fade-right" data-aos-duration="1500" >
                    <div className="d-flex">
                      <h4 className='emri'>{Translations(props?.langData, props?.selected_lang, 'projekti')}</h4>
                      <h4 className='emriNderteses'> &nbsp;
                        {
                          props?.selected_lang == "AL" ? el?.obiject_name_al :
                            props?.selected_lang == "EN" ? el?.obiject_name_en :
                              props?.selected_lang == "DE" ? el?.obiject_name_de :
                                el?.obiject_name_al
                        }
                      </h4>
                    </div>
                    <h4 className='emriNderteses '><i className="fa fa-location-dot" /> &nbsp; {
                      props?.selected_lang == "AL" ? el?.location_al :
                        props?.selected_lang == "EN" ? el?.location_en :
                          props?.selected_lang == "DE" ? el?.location_de :
                            el?.location_al
                    }</h4>
                  </div>
                  <Link to={"/projects"} className='shikoProjektin' data-aos-duration="2000" > {Translations(props?.langData, props?.selected_lang, 'shikoProjektet')}</Link>
                </div>
              </div>
            )}
          </Slider>
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// SECOND PART OF PAGE ///////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="slick_mobile_slider ">
          <h1 className=' col-10 mx-auto prorduketTitle pt-3 pb-3'>{Translations(props?.langData, props?.selected_lang, 'produkteTeKualitetitTeLarte')}</h1>
          <Slider {...slickSettings}>
            <div>
              <Link to={"/products/alumin"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top " alt="Alumni Project" src={AluminProdukt} />
                <div className="description mt-4 ">
                  <h5 className=" name-hide cardTitle">{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</h5>
                  <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'pershkrimiProdukteveTeAluminit')}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={"/products/PVC"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top" src={PVCimg} />
                <div className="description mt-4">
                  <h5 className=" name-hide cardTitle">PVC</h5>
                  <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'tekstiProduktevePVC')}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={"/products/glass"} style={{ textDecoration: 'none' }} className="card col-md-3 col-11 mx-auto border-0">
                <img className="card-img-top " src={SpiderGlassIMG} />
                <div className="description mt-4">
                  <h5 className=" name-hide cardTitle">{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')} </h5>
                  <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'tekstiProdukteveNgaXhami')}</p>
                </div>
              </Link>
            </div>
          </Slider>
        </div>

        <div className="container">
          <div className="materialet_punuese_container">
            <div className="col-md-12">
              <div className="materialet_punuese">
                <h1 className='porduketTitle pt-5 pb-5'>{Translations(props?.langData, props?.selected_lang, 'produkteTeKualitetitTeLarte')}</h1>
                <div className="aranzhoCardat pb-5 row " >
                  <Link to={"/products/alumin"} className="card col-md-3 col-12 "
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-duration="500"
                    data-aos-offset="0"
                  >
                    <img className="card-img-top " alt="Alumni Project" src={AluminProdukt} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle">{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</h5>
                      <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'pershkrimiProdukteveTeAluminit')}</p>
                    </div>
                  </Link>

                  <Link to={"/products/PVC"} className="card col-md-3 col-12 "
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-duration="500"
                    data-aos-offset="0">
                    <img className="card-img-top" src={PVCimg} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle">PVC</h5>
                      <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'tekstiProduktevePVC')}</p>
                    </div>
                  </Link>

                  <Link to={"/products/glass"} className="card col-md-3 col-12 "
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-duration="500"
                    data-aos-offset="0">
                    <img className="card-img-top " src={SpiderGlassIMG} />
                    <div className="description mt-4">
                      <h5 className=" name-hide cardTitle">{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')} </h5>
                      <p className="card-text ">{Translations(props?.langData, props?.selected_lang, 'tekstiProdukteveNgaXhami')}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// MATERIALI PUNUES ///////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}
        <div className=" descriptionOfWork " >
          <div className="row m-0">
            <div className="col-md-6 col-12 image_background ">
              {props?.selected_lang == "AL" ?
                <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12' src={ZgjedhShqip} alt="Glass Description" />
                : props?.selected_lang == "EN" ?
                  <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12 ' src={ZgjedhEN} alt="Glass Description" />
                  : props?.selected_lang == "DE" ?
                    <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12 ' src={ZgjedhDE} alt="Glass Description" />
                    :
                    <img data-aos="fade-right" data-aos-duration="1000" className='pershkrimiXhamitPVC img-fluid col-6 col-12' src={ZgjedhShqip} alt="Glass Description" />
              }
              {/* <img className='pershkrimiXhamitPVC col-6 col-12' src={imageDescription} alt="Glass Description" /> */}
            </div>
            <div className="col-md-6 image_description_background" >
              <div className="container mt-5" data-aos="fade-left" data-aos-duration="1000">
                <p className='profile_description_title mt-4' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProjektoDritarënTëndeNëTreHapa')}` }}></p>
                <p className='profile_description_pharagraph col-md-7' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'TekstiProjektimiDritares')}` }}></p>
                <a className='profile_project_button' href="/order">  {Translations(props?.langData, props?.selected_lang, 'ProjektoTekstiKrijoImazhin')} </a>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}




        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////// SLIDERI WITH Z-INDEX //////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="col-12 SliderDiv">
          <div className="container">
            <div className="row">
              <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
                <p className='titulliSlider col-md-md-8'>{Translations(props?.langData, props?.selected_lang, 'PlanifikimiDheRealizimiDeriNfund')}</p>
                <p className='pershkrimiSlider col-md-10'>{Translations(props?.langData, props?.selected_lang, 'PershkrimiTekSlideriKompleks')}
                </p>
              </div>
              <div className="col-md-6 parent_complex_slider_div">
                <div className="image-wrapper ">
                  <div className="">
                    <Swiper {...params}>
                      {SliderImages.map((el, index) => (
                        <SwiperSlide key={index}>
                          <div key={index}>
                            <img className="complexSliderIMG img-fluid col-md-12" src={el?.img} alt={`Slide ${index}`} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="selector_style">
                  <input
                    type="range"
                    min="0"
                    max="8"
                    value={activeIndex}
                    onChange={handleChange}
                  />
                  < p className="drag_and_discover_text  col-md-4 m-0">{Translations(props?.langData, props?.selected_lang, 'dragAndDiscoverSliderTekst')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////// Lajmet e FUNDIT /////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {/* <div className="container">
          <LatestNews />
        </div> */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////// IMAZHI ME TEKST /////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <div className="col-md-12 imazhiPrishtineDiv">
          <img className='imazhiPrishtines' src={imagePrishtina} alt="" />
          <img className='imazhiPrishtinesMobile img-fluid' src={imagePrishtinaMobile} alt="" />
          <div className="pershkrimiPrishtine col-md-4" data-aos="fade-right" data-aos-duration="1000">
            <p className='TitulliPrishtine col-md-10'>{Translations(props?.langData, props?.selected_lang, 'benifitetEdritareveTeKualitetitTeLarte')}</p>
            <hr className='vizaNdarese col-md-10' />
            <ul>
              <li>{Translations(props?.langData, props?.selected_lang, 'rriteVlerenShtepiake')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'rritjaSigurinShpise')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'reduktoPluhurinDheAlergjite')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'RritKomoditetinNeShtepi')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'ImprovedEnergyEfficiency')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'reduktoZhurmen')}</li>
              <li>{Translations(props?.langData, props?.selected_lang, 'zgjedhjeMeMirembajtjeTeUlet')}</li>
            </ul>
          </div>
        </div>


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ///////////////////////// PROJEKTET E REALIZUARA /////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        <div className="container  mt-5 projektetErealizuara">
          <h1 className='titulliProjekteve' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'projektetErealizuara')}` }}></h1>
          <p className='pershkrimiProjekteveTeRealizuara' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'tekstiPerProjektetErealizuara')}` }}></p>
          <div className="row">
            {slicedGalley?.map((el, index) => {
              return (
                <Link key={index} data-aos={index % 2 == 1 ? "fade-left" : "fade-right"} data-aos-duration="1200" className=" col-md-6 col-12 singleCard" to={`/project/${el?.page_unique_url}`}
                >
                  <div className='imazhi'
                    style={{ backgroundImage: `url(${AppApi + el?.bg_img})` }}
                  />
                  <div className="image_info ">
                    <h5 className="cardTitle mb-5">
                      {
                        props?.selected_lang == "AL" ? el?.obiject_name_al :
                          props?.selected_lang == "EN" ? el?.obiject_name_en :
                            props?.selected_lang == "DE" ? el?.obiject_name_de :
                              el?.obiject_name_al
                      }
                    </h5>
                    <div className="cardText mb-3 ">
                      <i className="fas fa-map-marker-alt locationMarker " aria-hidden="true" />
                      {
                        props?.selected_lang == "AL" ? el?.location_al :
                          props?.selected_lang == "EN" ? el?.location_en :
                            props?.selected_lang == "DE" ? el?.location_de :
                              el?.location_al
                      }
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>

        </div>


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////////// PARTNERET /////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        <div className="container mt-5 mb-5 partneretTane">
          {ourPartners.map((el, index) => {
            return (
              <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
            )
          })}
        </div>
      </div>





        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}


        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////////// Stafi Mengajmentit te larte /////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

      {/* <div className="container">
        <div className="our_staff_content">
          <p className='our_staff_page_title mt-5' dangerouslySetInnerHTML={{ __html: `Menaxhmenti i lartë i kompanisë M-Technologie` }} />
          <hr className='vizaNdarese ' />
          {ourStaff && <HighMenagmentStaff highManagement={ourStaff?.filter((elm) => elm?.is_high_management == true)} />}
        </div>
      </div> */}
    </>
  )
}


const mapStateToProps = state => {
  return {
    selected_lang: state.data.selected_lang,
    langData: state.data.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: () => dispatch(setLangData()),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);