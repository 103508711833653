import React from 'react'
import './latestNews.scss'
import { Link } from 'react-router-dom';
import axios from "../../axios"
import { useState } from 'react';
import { useEffect } from 'react';
const LatestNews = () => {
    // const AppApi = `https://api.m-technologie.com/`+

    const [news, setNews] = useState([])

    function getLatestNews() {
        axios.get("/api/admin/news/latest")
            .then((res) => setNews(res.data))
            .catch((err) => console.error('error fetching news', err))

    }
  
  
    useEffect(() => {
        getLatestNews()
    }, [])


    const AppApi = process.env.REACT_APP_API
    return (
        <div className="news_map_layout">
            <h2 className='latest_news_heading'>Lajmet e fundit</h2>
            <hr className='vizaNdarese mb-5 mt-3' />
            <div className="row mb-4 justify-content-center">
                {/* mapp here */}
                {news?.map(el => {

                    return (
                        <div className="col-md-6 col-lg-4 col-12 mb-4">
                            <img className='img-fluid  news_image ' src={`${AppApi}${el?.img_url}`} alt="" />
                            <p className='news-name ' dangerouslySetInnerHTML={{ __html: `${el?.title.slice(0, 1).toUpperCase()}${el?.title.slice(1).toLowerCase()}` }} />
                            <p className=' news-location my-3'>
                                <i className="fas fa-map-marker-alt " aria-hidden="true" />  {el?.location.slice(0, 1).toUpperCase() + el?.location.slice(1).toLowerCase()}
                            </p>
                            <div className='news_description  col-md-9 col-lg-10 col-12 '  >
                                <p dangerouslySetInnerHTML={{
                                __html: `${el?.news_text.length > 160 ? el?.news_text.slice(0, 160) + '...' : el?.news_text }`
                            }}></p>
                            </div>
                            <Link to={`/news/${el?.id}`} className='read_more_news ' role='button'>  Lexo më shumë</Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default LatestNews