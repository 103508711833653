import React, { useEffect, useState } from 'react'
import "./porosia.scss"
import $ from 'jquery';
import dritareKafe from '../../images/windowOptions/ngjyra/dritare-kafe.png'
import dritareBardhe from '../../images/windowOptions/ngjyra/dritare-bardh.png'
import dritareAntracid from '../../images/windowOptions/ngjyra/dritare-antracid.png'
import dritareAntracid_Bardhe from '../../images/windowOptions/ngjyra/dritare-bardh-antracid.png'

import dritareNjeKaheshe from '../../images/windowOptions/newOptionsDesign/dritare-nje-kraheshe.svg'
import dereDyKraheshe from '../../images/windowOptions/newOptionsDesign/dritare-dy-kraheshe.svg'
import njeKraheshe from '../../images/windowOptions/newOptionsDesign/dere-nje-kraheshe.svg'
import dyKraheshe from '../../images/windowOptions/newOptionsDesign/dere-dy-kraheshe.svg'
import Rreshqitese from '../../images/windowOptions/newOptionsDesign/dere-rreshqitese.svg'
import statike from '../../images/windowOptions/newOptionsDesign/dere-dritare-fikse.svg'
import shucoMVB from '../../images/products/M-technologie-PVC-Product-2.png'
import roletaMbi from '../../images/windowOptions/roletaMbi.png'
import roletaAnash from '../../images/windowOptions/roletaAnash.png'
import roletaVeneciane from '../../images/windowOptions/roletaVeneciane.png'
import paRoleta from '../../images/windowOptions/pa-roleta.svg'
import ngjyraBardhZi from '../../images/windowOptions/ngjyra/bardhZi.png'
// import ourPartners from "../../DB/partneret"
import axios from '../../axios'
import { toast } from 'react-toastify'
import Translations from '../../components/translations/translations';
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';

// console.log('window_data', window_data)
const Porosia = (props) => {


    const AppApi = `https://api.m-technologie.com/`


    const [ourPartners, setOurPartners] = useState([])

    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])




    console.log('Orderprops', props)
    const [ngjyra, setNgjyra] = useState("Kafe")
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])
    // const [color, setColor] = useState();
    const [initialOrderState, setInitialOrderState] = useState([{ gjatesia: "", gjeresia: "", sasia: "", selected_color: "", lloji_dritares: "", roletat: "" }]);
    const [costumer, setCostumer] = useState({ name_surname: "", address: "", city: "", email: "", phone_number: "" });
    // console.log('length, ', initialOrderState)
    const handleCostumer = (e, dataType) => {
        // e.preventDefault();
        let currentState = costumer;
        if (dataType === 'nameSurname') {
            let assignEmriMbiemri = Object.assign(currentState);
            assignEmriMbiemri.name_surname = e.target.value;
        }
        if (dataType === 'address') {
            let assignAddress = Object.assign(currentState);
            assignAddress.address = e.target.value;
        }
        if (dataType === 'city') {
            let assignCity = Object.assign(currentState);
            assignCity.city = e.target.value;
        }
        if (dataType === 'phone') {
            let assignPhoneNumber = Object.assign(currentState);
            assignPhoneNumber.phone_number = e.target.value;
        }
        if (dataType === 'email') {
            let assignEmail = Object.assign(currentState);
            assignEmail.email = e.target.value;
        }
        console.log('COSTUMER', costumer)
    };
    function selectWindowType(e, index, windowType) {
        console.log('lloji dritares', e, index, windowType)
        // e.preventDefault();
        let currentState = initialOrderState;

        // console.log('lloji dritares i zgjedhur', e?.target.value)
        if (windowType === 'dritareNjeKaheshe') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dritare Nje Kaheshe";
        }
        if (windowType === 'dritareDyKaheshe') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dritare Dy Kaheshe";
        }
        if (windowType === 'dereNjeKraheshe') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dere Nje Kaheshe";
        }
        if (windowType === 'dereDyKrahëshe') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dere Dy Krahëshe";
        }
        if (windowType === 'dereStatike') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dere Statike";
        }
        if (windowType === 'dereRreshqitese') {
            let assignEmail = Object.assign(currentState);
            assignEmail[index].lloji_dritares = "Dere Rreshqitese";
        }
    }
    function selectColor(e, index, selectedColor) {
        // e.preventDefault();
        // console.log('index', index, selectedColor)
        let currentState = initialOrderState;

        // console.log('lloji dritares i zgjedhur', e?.target.value)
        if (selectedColor === 'Bardh') {
            let assignColor = Object.assign(currentState);
            assignColor[index].selected_color = "Bardh";
            setNgjyra("Bardh");

        }
        if (selectedColor === 'Kafe') {
            let assignColor = Object.assign(currentState);
            assignColor[index].selected_color = "Kafe";
            setNgjyra("Kafe")

        }
        if (selectedColor === 'Antracid') {
            let assignColor = Object.assign(currentState);
            assignColor[index].selected_color = "Antracid";
            setNgjyra("Antracid")

        }
        if (selectedColor === 'Antracid-dhe-Zi') {
            let assignColor = Object.assign(currentState);
            assignColor[index].selected_color = "Antracid dhe Zi";
            setNgjyra("Antracid-dhe-Zi")

        }
    }
    var test = initialOrderState.slice(0, -1);
    console.log('shabanGashiState, ', initialOrderState)
    console.log('shabanGashiState, test', test)
    var [ordersToMap, setOrdersToMap] = useState(test)
    // console.log('lloji dritares i zgjedhur', initialOrderState)
    const addOrder = (e) => {
        // console.log('QIKJO initialOrderState', initialOrderState)
        // console.log('QIKJO ORDER', ordersToMap)
        e.preventDefault();
        console.log('initialOrderState.slice(-1)?.gjatesia', initialOrderState[initialOrderState.length - 1].gjatesia == "")

        if (initialOrderState[initialOrderState.length - 1]?.selected_color == "") {
            toast.error("Ju lutem zgjidheni nje ngjyrë!")
        }
        else if (initialOrderState[initialOrderState.length - 1].lloji_dritares == "") {
            toast.error("Ju lutem zgjidheni nje lloj të dritares!")
        }
        else if (initialOrderState[initialOrderState.length - 1].roletat == "") {
            toast.error("Ju lutem zgjidheni nje opsion per roletat!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.gjatesia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.gjeresia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.sasia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }


        else {

            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            setInitialOrderState(oldstate => [...oldstate, { gjatesia: "", gjeresia: "", sasia: "", selected_color: "", lloji_dritares: "", roletat: "" }]);
            setOrdersToMap(initialOrderState)

            const gjatesia = document.getElementById('window_height').value = ""
            const gjeresia = document.getElementById('window_width').value = ""
            const sasia = document.getElementById('quantity').value = ""
            const radioButtons = document.querySelectorAll('input[type="radio"]')
            for (let i = 0; i < radioButtons.length; i++) {
                radioButtons[i].checked = false;
            }
        }

    }

    function selectWindowBlindsType(e, index, blindsType) {
        // console.log('lloji roletave i zgjedhur', e?.target.roleta)
        let currentState = initialOrderState;

        console.log('lloji dritares i zgjedhur', e?.target.value)
        if (blindsType === 'roletaMbi') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].roletat = "Roleta Mbi";
        }
        if (blindsType === 'roletaAnash') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].roletat = "Roleta Anash";
        }
        if (blindsType === 'roletaVeneciane') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].roletat = "Roleta Veneciane";
        }
        if (blindsType === 'paRoleta') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].roletat = "Pa Roleta";
        }
        // console.log("zgjedhjaRoletave", currentState)
    }
    function setDimenzionet(e, index, dimensionsType) {
        console.log('lloji roletave i zgjedhur', e?.target.roleta)
        let currentState = initialOrderState;

        // console.log('dimenzionet', e?.target.value)
        if (dimensionsType === 'gjatesia') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].gjatesia = e?.target.value;

        }
        if (dimensionsType === 'gjeresia') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].gjeresia = e?.target.value;

        }
        if (dimensionsType === 'sasia') {
            let assignBlinds = Object.assign(currentState);
            assignBlinds[index].sasia = e?.target.value;

        }
        // console.log("zgjedhjaDimenzioneve", currentState)
    }

    const submitData = async (e) => {
        e.preventDefault();
        let data = {
            costumer: costumer,
            orders: initialOrderState,
        };
        if (initialOrderState[initialOrderState.length - 1]?.selected_color == "") {
            toast.error("Ju lutem zgjidheni nje ngjyrë!")
        }
        else if (initialOrderState[initialOrderState.length - 1].lloji_dritares == "") {
            toast.error("Ju lutem zgjidheni nje lloj të dritares!")
        }
        else if (initialOrderState[initialOrderState.length - 1].roletat == "") {
            toast.error("Ju lutem zgjidheni nje opsion per roletat!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.gjatesia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.gjeresia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }
        else if (initialOrderState[initialOrderState.length - 1]?.sasia == "") {
            toast.error("Ju lutem shenoni dimenzionet!")
        }


        else {
            await axios
                .post("/api/general/orders/post_new_order", data)
                .then((res) => {
                    // console.log('response', res)
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    toast.success('Porosia juaj është pranuar, do ju kontaktojmë sa më shpejtë!');
                    setInitialOrderState([{ gjatesia: "", gjeresia: "", sasia: "", selected_color: "", lloji_dritares: "", roletat: "" }])
                    const gjatesia = document.getElementById('window_height').value = ""
                    const gjeresia = document.getElementById('window_width').value = ""
                    const sasia = document.getElementById('quantity').value = ""
                    const radioButtons = document.querySelectorAll('input[type="radio"]')
                    for (let i = 0; i < radioButtons.length; i++) {
                        radioButtons[i].checked = false;
                    }
                })
                .catch((err) => {
                    console.log("error incoming", err);
                    toast.error('Kishte një problem gjatë kryerjes së porosisë, ju lutem provoni prap më vonë!');
                });
        }
    }
    // console.log('initialOrderState', initialOrderState)
    // console.log('costumer', costumer)

    console.log('ordersToMaparton', ordersToMap)

    const runThisShit = (el, index) => {
        // console.log('elementiShit', el)
        // console.log('indexdaja', index);
        // const removedItem = initialOrderState.splice(index, 1)[0]; // get the removed item itself, not an array containing it
        // console.log('removeSel initialOrderState', initialOrderState);
        // console.log('removeSel removedItem', removedItem);
        // const filteredOrders = initialOrderState.filter((element) => !ordersToMap.includes(element));
        // console.log('removeSel filteredOrders', filteredOrders);
        // setInitialOrderState(filteredOrders);
        // setOrdersToMap(ordersToMap.filter((element) => element !== removedItem));
        initialOrderState.splice(index - 1, 1);
        console.log('ordersToMapramadani', ordersToMap.splice(index, 1)


        )
    };



    console.log('final initial order state', initialOrderState)
    return (
        <>
            <div className='porosia'>
                <div className="container">
                    {/* {console.log("initialOrderState.length ", initialOrderState)} */}
                    {console.log('orders o vlla ', ordersToMap)}
                    {ordersToMap.length !== 0 ?
                        ordersToMap?.map((el, index) => {
                            console.log('orderstToMAPP', ordersToMap)
                            return (
                                <div className="col-12 mt-5">
                                    <div className="listing">
                                        <div className="row border p-3 orders_listing">
                                            <div className="col-md-3 ">
                                                <div className="d-flex">
                                                    <p className="numri_porosis col-md-2 mt-auto mb-auto">
                                                        {index + 1}.
                                                    </p>
                                                    <div className="ndarsi_imazhit col-md-10">
                                                        <img src={dereDyKraheshe} alt=" " className='dere_dy_kaheshe_option img-fluid' />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9 mt-auto mb-auto">
                                                <p className='selected_order'>{el?.lloji_dritares}</p>
                                                <p> Shuco MV8, Ngjyrë {el?.selected_color}, {el?.gjatesia}x{el?.gjeresia}cm, {el?.sasia} copë, {el?.roletat}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <h1 role='button' onClick={(e) => { e.preventDefault(); runThisShit(el, index) }}>X</h1> */}
                                    </div>
                                    <p>
                                    </p>
                                </div>
                            )

                        }) : ""
                    }
                    {
                        initialOrderState.slice(-1).map((el, index) => {
                            console.log(' el, index', el, index)
                            return (
                                <>


                                    {/* {console.log('firstEL', el, index)} */}
                                    <div className="colorSelect row">
                                        <div className="col-md-7 col-12 leftContent">
                                            <p className='title' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdertoDritarenNeMinutLabel')}` }} />
                                            <p className='paragrafi'>{Translations(props?.langData, props?.selected_lang, 'Teksti-pare-tek-konfiguratori-Label')}</p>
                                            <h5 className='mt-5 mb-4'>{Translations(props?.langData, props?.selected_lang, '1.Profili-konfiguratori-Label')}</h5>
                                            <div className="imgContent">
                                                <img src={shucoMVB} alt=" " className='profili' />
                                                <p className='mt-2'>{Translations(props?.langData, props?.selected_lang, 'Konfiguratori-Shuco-MV8-Label')}</p>
                                            </div>
                                            <h5 className='mt-4'>{Translations(props?.langData, props?.selected_lang, '2.Ngjyra-konfiguratori-Label')}</h5>

                                            <div className=" mt-4 col-xl-6 col-md-8 col-sm-12  ">
                                                <div className="row ngjyrat_e_dritareve ">
                                                    <div className="col-2 col-sm-1 color_selector">
                                                        <input type="radio" onClick={(e) => { selectColor(e, index + initialOrderState.length - 1, "Bardh") }} className="mx-auto whiteColor" id="Bardhë" name="radio" />
                                                        <div className="checkmark">
                                                            <p className='color_desc'>
                                                                {Translations(props?.langData, props?.selected_lang, 'Ngjyra-Bardhe-konfiguratori-Label')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 col-sm-1 color_selector">
                                                        <input type="radio" onClick={(e) => { selectColor(e, index + initialOrderState.length - 1, "Kafe") }} className="mx-auto brownDiv" id="Kafe" name="radio" value="kafe" />
                                                        <div className="checkmark">
                                                            <p className='color_desc'>
                                                                {Translations(props?.langData, props?.selected_lang, 'Ngjyra-Kafe-konfiguratori-Label')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 col-sm-1 color_selector">
                                                        <div className="WHITE_antracid_div"></div>
                                                        <input type="radio" onClick={(e) => { selectColor(e, index + initialOrderState.length - 1, "Antracid") }} className="mx-auto antracidDiv" id="Antracid" name="radio" />
                                                        <div className="checkmark">
                                                            <p className='color_desc'>
                                                                {Translations(props?.langData, props?.selected_lang, 'Ngjyra-Antracid-konfiguratori-Label')}


                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 col-sm-1 color_selector">

                                                        <label className="white_antracid_divv" htmlFor="testBardhZi" style={{ backgroundImage: `url(${ngjyraBardhZi})` }} />
                                                        <input type="radio" onClick={(e) => { selectColor(e, index + initialOrderState.length - 1, "Antracid-dhe-Zi") }} className="mx-auto white_antracid_div" value="AntracidDheBardh" id="testBardhZi" name="radio" />
                                                        <div className="checkmark">
                                                            <p className='color_desc_white_antracid' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Ngjyra-Antracid-dhe-Bardh-konfiguratori-Label')}` }}>

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-5 col-12 rightContent">
                                            {console.log('ngjyrangjyrangjyrangjyrangjyra', ngjyra)
                                            }                                            {ngjyra == "Bardh" ?

                                                <img src={dritareBardhe} alt=" " className='dritare1  img-fluid' /> :
                                                ngjyra == "kafe" ?
                                                    <img src={dritareKafe} alt=" " className='dritare1  img-fluid' />
                                                    :
                                                    ngjyra == "Antracid" ?
                                                        <img src={dritareAntracid} alt=" " className='dritare1  img-fluid' />
                                                        :
                                                        ngjyra == "Antracid-dhe-Zi" ?
                                                            <img src={dritareAntracid_Bardhe} className='dritare1  img-fluid' />
                                                            :
                                                            <img src={dritareKafe} alt=" " className='dritare1  img-fluid' />

                                            }
                                        </div>
                                    </div>
                                    <hr className='vijaNdarese' />

                                    <div className="windowTypes">
                                        <h5>{Translations(props?.langData, props?.selected_lang, '3.Lloji-konfiguratori-Label')}</h5>
                                        <div className="col-12 window_selector">

                                            <div className="row displace_windows_evenly pt-4">
                                                <div className="paretn_window_type mb-5 col-xl-3 col-md-5 col-6">
                                                    <input onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dritareNjeKaheshe") }} type="radio" name="Lloji" id="dritareNjeKaheshe" className="window_radio_button" value="DritareNjeKraheshe" required />
                                                    <label htmlFor="dritareNjeKaheshe" className='tipet_e_dritareve test test1'>
                                                        <img src={dritareNjeKaheshe} alt=" " className='dritare_nje_kaheshe_option img-fluid' />
                                                        <p className='description_window_type_text'>{Translations(props?.langData, props?.selected_lang, 'Dritare-një-krahëshe-konfiguratori-Label')}</p>
                                                    </label>
                                                    <div className="checkmark" />
                                                </div>

                                                <div className="paretn_window_type mb-5 col-xl-2 col-md-6 col-6">
                                                    <input type="radio" name="Lloji" id="dritareDyKaheshe" className="window_radio_button" value="DereDyKraheshe" required onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dritareDyKaheshe") }} />
                                                    <label htmlFor="dritareDyKaheshe" className='tipet_e_dritareve test '>
                                                        <img src={dereDyKraheshe} alt=" " className='dritare_nje_kaheshe_option img-fluid' />
                                                        <p className='description_window_type_text'>
                                                            {Translations(props?.langData, props?.selected_lang, 'Dritare-dy-krahëshe-konfiguratori-Label')}
                                                        </p>
                                                    </label>
                                                    <div className="checkmarkD2k" />
                                                </div>

                                                <div className="paretn_bigger_window_type mt-2 col-xl-2 col-md-6 col-6">
                                                    <input onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dereNjeKraheshe") }} type="radio" name="Lloji" id="dereNjeKraheshe" className="window_radio_button" value="dereNjeKraheshe" required />
                                                    <label htmlFor="dereNjeKraheshe" className='tipet_e_dritareve'>
                                                        <img src={njeKraheshe} alt=" " className='dere_dy_kaheshe_option1 img-fluid' />
                                                        <p className='description_window_type_text'>{Translations(props?.langData, props?.selected_lang, 'Dere-një-krahëshe-konfiguratori-Label')}</p>
                                                    </label>
                                                    <div className="window_bigger_option_checkmark" />
                                                </div>

                                                <div className="paretn_bigger_window_type mt-2 col-xl-2 col-md-6 col-6">
                                                    <input onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dereDyKrahëshe") }} type="radio" name="Lloji" id="dereDyKrahëshe" className="window_radio_button" value="dereDyKrahëshe" required />
                                                    <label htmlFor="dereDyKrahëshe" className='tipet_e_dritareve test1'>
                                                        <img src={dyKraheshe} alt=" " className='dere_dy_kaheshe_option2 img-fluid' />
                                                        <p className='description_window_type_text'>{Translations(props?.langData, props?.selected_lang, 'Dere-dy-krahëshe-konfiguratori-Label')}</p>
                                                    </label>
                                                    <div className="two_dors_window_option_checkmark" />
                                                </div>

                                                <div className="responsive_costum_style row mt-5">
                                                    <div className="paretn_window_type mb-5 col-xl-3 col-md-6 col-6">
                                                        <input onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dereStatike") }} type="radio" name="Lloji" id="dereStatike" className="window_radio_button" value="dereStatike" required />
                                                        <label htmlFor="dereStatike" className='tipet_e_dritareve'>
                                                            <img src={statike} alt=" " className='dere_nje_kaheshe_option img-fluid' />
                                                            <p className='description_window_type_text'>{Translations(props?.langData, props?.selected_lang, 'Dere/dritare-fikse-konfiguratori-Label')}</p>
                                                        </label>
                                                        <div className="window_bigger_option_checkmark2" />
                                                    </div>
                                                    <div className="paretn_window_type mb-5  col-xl-4 col-md-6 col-6">
                                                        <input onChange={(e) => { selectWindowType(e, index + initialOrderState.length - 1, "dereRreshqitese") }} type="radio" name="Lloji" id="dereRreshqitese" className="window_radio_button" value="dereRreshqitese" required />
                                                        <label htmlFor="dereRreshqitese" className='tipet_e_dritareve mt-0 test1'>
                                                            <img src={Rreshqitese} alt=" " className='dere_dy_kaheshe_option img-fluid' />
                                                            <p className='description_window_type_text'>{Translations(props?.langData, props?.selected_lang, 'Dere-rreshqitese-konfiguratori-Label')}</p>
                                                        </label>
                                                        <div className="two_dors_window_option_checkmark2" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <hr className='vijaNdarese' />
                                    <div className="roletat">
                                        <h5>{Translations(props?.langData, props?.selected_lang, '4.Roleta-konfiguratori-Label')}</h5>
                                        <div className="col-12 blinds_selector">
                                            <div className="row">
                                                <div className="roletat_mbi col-md-3 col-3" >
                                                    <input onChange={(e) => { selectWindowBlindsType(e, index + initialOrderState.length - 1, "roletaMbi") }} name="roleta" type="radio" id="roletaMbi" className="blinds_radio_button" value="Roleta-Mbi" required />
                                                    <label htmlFor="roletaMbi" className='blinds_over '>
                                                        <img src={roletaMbi} alt="" className='blind_option_img img-fluid' />
                                                        <p className='description_blinds_text'>{Translations(props?.langData, props?.selected_lang, 'Roleta-Mbi-konfiguratori-Label')}</p>
                                                    </label>
                                                    <div className="checkmark" />
                                                </div>
                                                <div className="roletat_mbi col-md-3 col-3" >
                                                    <input onChange={(e) => { selectWindowBlindsType(e, index + initialOrderState.length - 1, "roletaAnash") }} name="roleta" type="radio" id="roletaAnash" className="blinds_radio_button" value="Roleta-Anash" required />
                                                    <label htmlFor="roletaAnash" className='blinds_over'>
                                                        <img src={roletaAnash} alt=" " className='blind_side_option img-fluid' />
                                                        <p className='description_blinds_text'>{Translations(props?.langData, props?.selected_lang, 'Roleta-Anash-konfiguratori-Label')}</p>

                                                    </label>
                                                    <div className="checkmark" />
                                                </div>
                                                <div className="roletat_mbi roletat_veneciane col-md-3 col-3" >
                                                    <input onChange={(e) => { selectWindowBlindsType(e, index + initialOrderState.length - 1, "roletaVeneciane") }} name="roleta" type="radio" id="roletaVeneciane" className="blinds_radio_button" value="Roleta-Veneciane" required />
                                                    <label htmlFor="roletaVeneciane" className='blinds_over'>
                                                        <img src={roletaVeneciane} alt=" " className='blind_veneciane_option img-fluid' />
                                                        <p className='description_blinds_text'>{Translations(props?.langData, props?.selected_lang, 'Roleta-Veneciane-konfiguratori-Label')}</p>

                                                    </label>
                                                    <div className="checkmark" />
                                                </div>
                                                <div className="roletat_mbi col-md-3 col-3" >
                                                    <input onChange={(e) => { selectWindowBlindsType(e, index + initialOrderState.length - 1, "paRoleta") }} name="roleta" type="radio" id="paRoleta" className="blinds_radio_button" value="Roleta-Veneciane" required />
                                                    <label htmlFor="paRoleta" className='blinds_over'>
                                                        <img src={paRoleta} alt=" " className='blind_veneciane_option img-fluid' />
                                                        <p className='description_blinds_text'>{Translations(props?.langData, props?.selected_lang, 'Pa-Roleta-konfiguratori-Label')}</p>

                                                    </label>
                                                    <div className="checkmark" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr className='vijaNdareseRoleta' />
                                    <div className="dimensionetSasia row">
                                        <div className="col-xl-3 col-md-6 col-sm-6">
                                            <h5>{Translations(props?.langData, props?.selected_lang, '5.Dimenzionet-konfiguratori-Label')}</h5>
                                            <div className="inputFields row">
                                                <div className=" col-6  inputContent">
                                                    <input onChange={(e) => { setDimenzionet(e, index + initialOrderState.length - 1, "gjatesia") }} placeholder={`${Translations(props?.langData, props?.selected_lang, 'Gjatesia-konfiguratori-Label')}`} type="number" name='window_height' id='window_height' required />
                                                    <span>cm</span>
                                                </div>
                                                <div className=" col-6  inputContent">
                                                    <input onChange={(e) => { setDimenzionet(e, index + initialOrderState.length - 1, "gjeresia") }} placeholder={`${Translations(props?.langData, props?.selected_lang, 'Gjerësia-konfiguratori-Label')}`} type="number" name='window_width' id='window_width' required />
                                                    <span>cm</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 col-sm-6 text-start">
                                            <h5>{Translations(props?.langData, props?.selected_lang, '6.Sasia-konfiguratori-Label')}</h5>
                                            <div className="inputFields row">
                                                <div className=" col-6  inputContent1">
                                                    <input onChange={(e) => { setDimenzionet(e, index + initialOrderState.length - 1, "sasia") }} type="number" name='quantity' id='quantity' required />
                                                    <span>{Translations(props?.langData, props?.selected_lang, 'Copë-konfiguratori-Label')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='vijaNdareseSasia' />
                                    <div className="col-8 add_order">
                                        <button onClick={addOrder} className=' mt-5 mb-5  add_new_order'>
                                            <span> + SHTO </span>
                                        </button>
                                    </div>
                                    {/* TE DHENAT E DERGUESIT TE POROSISE */}
                                    <div className="your_data col-12">
                                        <p className='informativ_text_for_user mt-5 col-12'>{Translations(props?.langData, props?.selected_lang, 'Teksti-Plotsimit-te-dhenave-konfiguratori-Label')}</p>

                                        <div className="personal_data_input_fields col-12 mt-4">
                                            <div className=" row">
                                                <div className=" col-md-4 mb-5  inputContent ">
                                                    <input onChange={(e) => { handleCostumer(e, "nameSurname") }} type="text" required />
                                                    <span className='name_and_surname '>{Translations(props?.langData, props?.selected_lang, 'Emri-dhe-mbiemri-konfiguratori-Label')}</span>

                                                </div>
                                                <div className=" col-md-4 mb-5  inputContent">
                                                    <input onChange={(e) => { handleCostumer(e, "address") }} name="address" type="text" required />
                                                    <span className='name_and_surname '>{Translations(props?.langData, props?.selected_lang, 'Adresa-konfiguratori-Label')}</span>

                                                </div>
                                                <div className=" col-md-4 mb-5  inputContent">
                                                    <input onChange={(e) => { handleCostumer(e, "city") }} type="text" required />
                                                    <span className='name_and_surname '>{Translations(props?.langData, props?.selected_lang, 'Qyteti-konfiguratori-Label')}</span>

                                                </div>
                                                <div className=" col-md-4 mb-5  inputContent">
                                                    <input onChange={(e) => { handleCostumer(e, "phone") }} type="number" required
                                                    />
                                                    <span className='name_and_surname '>{Translations(props?.langData, props?.selected_lang, 'Numri-telefonit-konfiguratori-Label')}</span>

                                                </div>
                                                <div className=" col-md-4 mb-5  inputContent">
                                                    <input onChange={(e) => { handleCostumer(e, "email") }} type="email" required />
                                                    <span className='name_and_surname '>{Translations(props?.langData, props?.selected_lang, 'E-mail-konfiguratori-Label')}</span>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="send_order_and_button">
                                            <p className='send_to_contact_you_text  mb-5'>{Translations(props?.langData, props?.selected_lang, 'Dergo-Porosine-teksti-konfiguratori-Label')}</p>

                                            <button className=' mb-5 send_this_order' onClick={submitData}> {Translations(props?.langData, props?.selected_lang, 'Dërgo-porosinë-konfiguratori-Label')} </button>
                                        </div>
                                    </div>

                                </>

                            )
                        })

                    }

                </div>
            </div >
            <div className="col-md-11 mx-auto mt-5 mb-5 partneretTane">
                {ourPartners?.map((el, index) => {
                    return (
                        <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                    )
                })}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Porosia);