import React, { useEffect, useState } from 'react'
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import './aboutUs.scss';
import aboutUsCompanyImage from "../../images/aboutUs/about_us_banner.png"
import insideCompanyImage2 from "../../images/aboutUs/Reth-nesh-2.jpg"
import insideCompanyImage1 from "../../images/aboutUs/Rreth-nesh-1.JPG"
import axios from '../../axios';
import Translations from '../../components/translations/translations';


const AboutUs = (props) => {
    const AppApi = `https://api.m-technologie.com/`

    const [ourPartners, setOurPartners] = useState([])

    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <div className="about_us_layout">
            <img className="img-fluid about_us_company_image" src={aboutUsCompanyImage} />
            <div className="container">
                <div className="about_us_content">
                    <p className='about_us_page_title mt-5'>{Translations(props?.langData, props?.selected_lang, 'AboutUs')}</p>
                    <p className='company_description mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'paragrafiPareTekRrethNeshe')}` }}></p>
                    <img className='img-fluid about_us_inside_company_image' src={insideCompanyImage1} alt="Inside company image" />
                    <p className='company_description mt-5 mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'paragrafiDyteTekRrethNeshe')}` }}>

                    </p>
                    <img className='img-fluid about_us_inside_company_image' src={insideCompanyImage2} alt="Inside company image" />
                </div>
                <div className="mt-5 mb-5 partneretTane">
                    {ourPartners?.map((el, index) => {
                        return (
                            <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                        )
                    })}
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);