import React, { useEffect, useState } from 'react';
import "./style.scss";

import whiteCompanyLogo from '../../../images/companyLogo.png';
import blueCompanyLogo from '../../../images/blueCompanyLogo.png';


import Translations from '../../translations/translations';
import { setLangData, setSelectedLang, setProjectsData } from "../../../redux/Functions/actions";
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const NewHeader = (props) => {
  // const [pathi, setPathi] = useState()
  const pathi = useLocation();
  console.log('headerLocation', pathi)
  const regex = /^\/news\/\d+$/;


  const style = [
    { color: "red" },
    { zIndex: 999999999999999999999 }
  ];
  const handleMouseOver = () => {
    const elements = document.querySelectorAll(".onHoverInlineStyle");
    elements.forEach((el) => {
      el.style.display = "block";
    });

  };
  const handlenoSubLinksMouseOver = () => {
    const headerLinks = document.querySelectorAll("#show-on-hover");
    headerLinks.forEach((el) => {
      el.style.color = "white !important"
    });

  };
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="header_layout col-12"
        style={
          pathi?.pathname === "/projects" ||
            pathi?.pathname === "/contact" ||
            pathi?.pathname === "/mirenjohjet" ||
            pathi?.pathname === "/certifikatat" ||
            pathi?.pathname === "/katallogjet" ||
            pathi?.pathname === "/katallogjet/" ||
            pathi?.pathname === "/standardiXhamit" ||
            pathi?.pathname === "/news" ||
            regex.test(pathi?.pathname) ||
            pathi?.pathname === "/order" ?
            { position: "absolute", background: "linear-gradient(0deg, #1A2246, #1A2246)" }
            :
            {}
        }
      >
        <nav className="navbar navbar-expand-lg navbar-light header_background">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img className='whiteCompanyLogo'
                src={whiteCompanyLogo}
                alt="navbar-brand" />

            </Link>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              {/* {console.log("pathi?.pathname",pathi?.pathname)} */}
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0" onMouseOver={
                pathi?.pathname === "/projects" ||
                  pathi?.pathname === "/contact" ||
                  pathi?.pathname === "/mirenjohjet" ||
                  pathi?.pathname === "/certifikatat" ||
                  pathi?.pathname === "/katallogjet" ||
                  pathi?.pathname === "/katallogjet/" ||
                  pathi?.pathname === "/standardiXhamit" ||
                  pathi?.pathname === "/order" ?
                  handleMouseOver : null}>
                <li className="nav-item" id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet about_us_menu_link" aria-current="page" to="/aboutUs">{Translations(props?.langData, props?.selected_lang, 'AboutUs')}</Link>
                  <div className="projects_submenu_links">
                    <ul className='container mx-auto d-flex flex-column'>
                      <hr className='vizaNdarese' style={{ marginTop: "33px" }} />
                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo' src={blueCompanyLogo} alt="test" />
                      </Link>
                      <Link to="/management" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'MenagjmentiLabel')}</Link>
                      <Link to="/socialResponsebility">{Translations(props?.langData, props?.selected_lang, 'pergjegjsiaSocialeLabel')}</Link>
                      {/* <Link to="/our-staff" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'ourStaffLabel')}</Link> */}
                      {/* <Link to="/news" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'LajmetLabel')}</Link> */}
                    </ul>
                  </div>
                </li>
                <li className="nav-item " onMouseOver={handlenoSubLinksMouseOver} id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet" to="/projects">{Translations(props?.langData, props?.selected_lang, 'ourProjects')}</Link>
                  <div className="projects_submenu_links" >
                    <ul className='container'>
                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo ' src={blueCompanyLogo} alt="test" />
                      </Link>
                    </ul>
                  </div>
                </li>
                <li className="nav-item" id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet" to="/products" >{Translations(props?.langData, props?.selected_lang, 'whatWeOffer')}</Link>
                  <div className="projects_submenu_links">
                    <ul className='container mx-auto d-flex flex-column'>
                      <hr className='vizaNdarese' style={{ marginTop: "33px" }} />
                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo ' src={blueCompanyLogo} alt="test" />
                      </Link>
                      <Link to="products/alumin" >{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</Link >
                      <Link to="products/PVC" >PVC</Link>
                      <Link to="products/glass" >{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')}</Link>
                    </ul>
                  </div>
                </li>
                <li className="nav-item" id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet" to="/order" >{Translations(props?.langData, props?.selected_lang, 'Konfiguratori')}</Link>
                  <div className="projects_submenu_links">
                    <ul className='container'>
                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo ' src={blueCompanyLogo} alt="test" />
                      </Link>
                    </ul>
                  </div>
                </li>
                <li className="nav-item" id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet" to="/forPublic">{Translations(props?.langData, props?.selected_lang, 'forPublic')}</Link>
                  <div className="projects_submenu_links">
                    <ul className='container mx-auto d-flex flex-column '>
                      <hr className='vizaNdarese' style={{ marginTop: "33px" }} />

                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo ' src={blueCompanyLogo} alt="test" />
                      </Link>
                      <Link className="" to="/katallogjet" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'KatalogjetLabel')}</Link>
                      <Link className="" to="/mirenjohjet" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'MirënjohjetLabel')}</Link>
                      <Link className="" to="/certifikatat" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'ÇertifikatatLabel')}</Link>
                      <Link className="" to="/standardiXhamit" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'Standardi-i-përputhshmërisë-Label')}</Link>
                    </ul>
                  </div>
                </li>
                <li className="nav-item" id='visable-links'>
                  <Link id='show-on-hover' className="nav-link linqet" to="/contact">{Translations(props?.langData, props?.selected_lang, 'kontaktimi')}</Link>
                  <div className="projects_submenu_links">
                    <ul className='container'>
                      <Link className="onHoverInlineStyle col-3" to="/">
                        <img className='blueCompanyLogo ' src={blueCompanyLogo} alt="test" />
                      </Link>
                    </ul>
                  </div>
                </li>
              </ul>
              <hr className='vija_ndarese_header' />
              <div className=" languages">
                <div className="languages_options">
                  <p id='show-on-hover' role="button" className={props?.selected_lang == "AL" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("AL") }}>AL</p>
                  <p id='show-on-hover' role="button" className={props?.selected_lang == "EN" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("EN") }}>EN</p>
                  <p id='show-on-hover' role="button" className={props?.selected_lang == "DE" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("DE") }}>DE</p>
                </div>
                <Link className="btn b2bButton " to='/B2B'>B2B</Link>
              </div>
            </div>
          </div>
        </nav >
      </div >


      <div className='responsive_mobile_header'>
        <button
          onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}
          className={`hamburger-button ${isOpen ? "open" : "close"}`}
        />
        <div className={`panel ${isOpen ? "open" : "close"}`}>
          <ul>
            <li>
              <Link to="/aboutUs" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }} className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'AboutUs')}</Link>
              <div className="other_mobile_links ">
                <Link to="/management" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'MenagjmentiLabel')}</Link>
                <Link to="/socialResponsebility" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'pergjegjsiaSocialeLabel')}</Link>
              </div>
            </li>
            <li>
              <Link to="/projects" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }} className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'ourProjects')}</Link>
            </li>

            <li>
              <Link to="/products" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }} className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'whatWeOffer')}</Link>
              <div className="other_mobile_links ">
                <Link to="/products/alumin" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</Link>
                <Link to="/products/PVC" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>PVC</Link>
                <Link to="/products/glass" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')}</Link>
              </div>
            </li>
            <li>
              <Link to="/order" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }} className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'Konfiguratori')}</Link>
            </li>
            <li>
              <Link to="/forPublic" className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'forPublic')}</Link>
              <div className="other_mobile_links ">
                <Link to="/katallogjet" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'KatalogjetLabel')}</Link>
                <Link to="/mirenjohjet" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'MirënjohjetLabel')}</Link>
                <Link to="/certifikatat" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'ÇertifikatatLabel')}</Link>
                <Link className="" to="/standardiXhamit" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>{Translations(props?.langData, props?.selected_lang, 'Standardi-i-përputhshmërisë-Label')}</Link>
              </div>
            </li>

            <li>
              <Link to="/contact" onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }} className='mobile_menu_titles'>{Translations(props?.langData, props?.selected_lang, 'kontaktimi')}</Link>
            </li>
            <hr className='vija_ndarese_header' />
            <div className=" languages">
              <div className="languages_options">
                <p role="button" className={props?.selected_lang == "AL" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("AL"); setOpen(false) }}>AL</p>
                <p role="button" className={props?.selected_lang == "EN" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("EN"); setOpen(false) }}>EN</p>
                <p role="button" className={props?.selected_lang == "DE" ? 'languagesButtons selectedLang' : 'languagesButtons'} onClick={(e) => { e.preventDefault(); props?.setSelectedLang("DE"); setOpen(false) }}>DE</p>
              </div>
              <Link className="btn b2bButton " to='/B2B' onClick={() => { setOpen(!isOpen); window.scrollTo(0, 0) }}>B2B</Link>
            </div>
          </ul>

        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  return {
    selected_lang: state.data.selected_lang,
    langData: state.data.langData,
    projectsData: state.data.projectsData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: (labels) => dispatch(setLangData(labels)),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang)),
    setProjectsData: (data) => dispatch(setProjectsData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);


