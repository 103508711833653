import React, { useEffect, useState } from 'react'
import "./ourProducts.scss"
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Translations from '../../components/translations/translations';

import pvcImage from "../../images/products/M-technologie-PVC-Product-2.png"
import aluminImage from "../../images/products/Produktet-e-Aluminit.jpg"
import glassImage from "../../images/products/Spider-Glass.png"
import productsBannes from "../../images/products/Benneri_Cka-ofrojme.jpg"
import axios from '../../axios';

const OurProducts = (props) => {
    const AppApi = `https://api.m-technologie.com/`

    const [ourPartners, setOurPartners] = useState([])


    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])


    return (
        <div className="our_prducts_layout">
            <div className='opening_parent_slider'>
                <div className="banner_layout">
                    <img src={productsBannes} className="single_product_image_banner img-fluid" alt="..." />
                    <div className="container banner_description">
                        <p className='col'>{Translations(props?.langData, props?.selected_lang, 'PershkrimiBaneritTekFaqjaProdukteve')}</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="d-flex  flex-wrap justify-content-between mt-5 mb-5">
                    <div className=" col-md-7 col-12 single_product_layout">
                        <h1 className='single_product_title'>{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaPVC')}</h1>
                        <p className='single_product_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-ofron-dyer-dhe-dritare-nga-PVC,-përmes-teknologjisë-dhe-paisjeve-Label')}` }}>
                        </p>
                        <Link to="/products/PVC" className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                    </div>
                    <img className="col-sm-3 col-md-4 col-12 img-fluid" src={pvcImage} />
                </div>
                <div className="d-flex flex-wrap  justify-content-between mt-4 mb-2">
                    <img className="col-sm-3 col-md-4 col-12 " src={aluminImage} />
                    <div className="col-md-7 col-12 single_product_layout">
                        <h1 className='single_product_title'>{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaAlumini')}</h1>
                        <p className='single_product_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Profilet-vertikale-dhe-horizontale-të-këtij-sistemi-të-fasadës-kombinohen-lehtë-me--Label')}` }}>

                        </p>
                        <Link to="/products/alumin" className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                    </div>
                </div>
                <div className="d-flex flex-wrap  justify-content-between mt-4 mb-5">
                    <div className="col-md-7 col-12 single_product_layout">
                        <h1 className='single_product_title'>{Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitTitle')}</h1>
                        <p className='single_product_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetExhamit-Teksti-tek-produktet-tona-Label')}` }}>
                        </p>
                        <Link to="/products/glass" className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'Më-shumë-LabellProducts')}</Link>
                    </div>
                    <img className="col-sm-3 col-md-4 col-12 img-fluid" src={glassImage} />
                </div>
            </div>
            <div className="container mt-2 mb-5 partneretTane">
                {ourPartners?.map((el, index) => {
                    return (
                        <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                    )
                })}
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurProducts);