import { useState } from 'react';
import { React, useEffect } from 'react'
import { Link } from 'react-router-dom';
import pageData from "../../DB/singlePageData.json";
import "./ourProjects.scss"

import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import Translations from '../../components/translations/translations';
import { connect } from 'react-redux';

import ReactPaginate from 'react-paginate';
import axios from '../../axios';

const OurProjects = (props) => {



  const [shouldShowFilters, setShouldShowFilters] = useState("hideFilters")
  const [filteredData, setFilteredData] = useState("")
  const [filterOptions, setFilterOptions] = useState([])
  const [pageData, setPageData] = useState([])
  const AppApi = `https://api.m-technologie.com/`

  const getAllProjects = () => {
    axios.get("/api/client/homepage/all-projects")
      .then((res) => {
        setPageData(res.data)
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })
  }
  useEffect(() => {
    getAllProjects()
  }, [])


  function getFiltersFromDB() {

    axios.get("/api/client/homepage/project-types")
      .then((res) => {
        setFilterOptions(res.data)
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })

    // setFilterOptions([...new Set(pageData?.map((el) => el?.business_type_id))])
  }
  console.log("filterOptions", filterOptions)
  // const map = new Map(Object.entries(filterOptions))
  // console.log('mapRamadani', map)
  function showFilter() {
    setShouldShowFilters('showFilters');
    if (shouldShowFilters == "showFilters") {
      setShouldShowFilters("hideFilters");
    }
  }
  function filterProjects(e) {
    setFilteredData(pageData.filter((el) => el?.business_type_id == e));
  }


  // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    window.scrollTo(0, 0);
  };

  const render = () => {
    const itemsPerPage = 10;
    const offset = currentPage * itemsPerPage;


    const filteredPageData = filteredData.slice(offset, offset + itemsPerPage);

    return (
      <>
        {filteredPageData?.map((el, index) => {
          // console.log("firstel", el)
          return (
            <Link key={index}
              data-aos={index % 2 == 1 ? "fade-left" : "fade-right"}
              data-aos-duration="1000"
              className=" col-md-6 col-12 singleCard"
              to={`/project/${el?.page_unique_url}`}
            >
              <div className='' style={{ backgroundImage: `url(${AppApi + el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} />
              <div className="image_info">
                <h5 className="cardTitle mb-5">
                  {
                    props?.selected_lang == "AL" ? el?.obiject_name_al :
                      props?.selected_lang == "EN" ? el?.obiject_name_en :
                        props?.selected_lang == "DE" ? el?.obiject_name_de :
                          el?.obiject_name_al
                  }
                </h5>
                <div className="cardText mb-3 ">
                  <i className="fas fa-map-marker-alt  " aria-hidden="true" /> &nbsp;
                  {
                    props?.selected_lang == "AL" ? el?.location_al :
                      props?.selected_lang == "EN" ? el?.location_en :
                        props?.selected_lang == "DE" ? el?.location_de :
                          el?.location_al
                  }
                </div>
              </div>
            </Link>
          )
        })}
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </>);
  };
  // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\


  // -------------------- ALL PAGE DATA PAGINATION ---------------------\\
  const [allPageDataCurrentPage, setAllPageDataCurrentPage] = useState(0);

  const handleAllPageDataClick = (data) => {
    setAllPageDataCurrentPage(data.selected);
    window.scrollTo(0, 0);
  };

  const renderAllPageData = () => {
    const AllitemsPerPage = 10;
    const offset = allPageDataCurrentPage * AllitemsPerPage;

    const allPageData = pageData.slice(offset, offset + AllitemsPerPage);


    // console.log('allPageData', allPageData?.length)
    return (
      <>
        {allPageData?.map((el, index) => {
          return (
            <Link
              key={index}
              data-aos={index % 2 == 1 ? "fade-left" : "fade-right"}
              data-aos-duration="1000"
              className=" col-md-6 col-12 singleCard"
              to={`/project/${el?.page_unique_url}`}
            >
              <div className='' style={{ backgroundImage: `url(${AppApi + el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} />
              <div className="image_info">
                <h5 className="cardTitle mb-5">
                  {
                    props?.selected_lang == "AL" ? el?.obiject_name_al :
                      props?.selected_lang == "EN" ? el?.obiject_name_en :
                        props?.selected_lang == "DE" ? el?.obiject_name_de :
                          el?.obiject_name_al
                  }
                </h5>
                <div className="cardText mb-3 ">
                  <i className="fas fa-map-marker-alt  " aria-hidden="true" /> &nbsp;
                  {
                    props?.selected_lang == "AL" ? el?.location_al :
                      props?.selected_lang == "EN" ? el?.location_en :
                        props?.selected_lang == "DE" ? el?.location_de :
                          el?.location_al
                  }
                </div>
              </div>
            </Link>
          )
        })}
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={Math.ceil(pageData?.length / AllitemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleAllPageDataClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </>);
  };

  // -------------------- ALL PAGE DATA PAGINATION ---------------------\\



  useEffect(() => {
    window.scrollTo(0, 0);
    getFiltersFromDB();
  }, [window.location.reload])

  // console.log('filteredData', filteredData?.length)
  return (
    <div className="container ">
      <div className=" allProjects">
        <div className="tekstiProjekteveTengjashme">
          <div className="row">
            <h1 className='titulliProjekteve col-12'> {Translations(props?.langData, props?.selected_lang, 'projektetErealizuara')}</h1>
            <div className="row ">

              <p className='filter col-6'>
                <button onClick={showFilter}>
                  <i className="fas fa-light fa-sliders filter_icon" />  {Translations(props?.langData, props?.selected_lang, 'FiltroLabel')}
                </button>
              </p>
              <p className='numrimiProjekteve col-6'>{Translations(props?.langData, props?.selected_lang, 'ShowingLabel')}: {filteredData?.length !== 0 ? filteredData?.length : pageData?.length} {Translations(props?.langData, props?.selected_lang, 'ProjekteLabel')}</p>
            </div>
            <div className={"container " + shouldShowFilters}>
              <hr className='vijaNdarese col-12' />
              <div className="d-flex flex-wrap">
                {shouldShowFilters == "showFilters" ? filterOptions.map((el, index) =>
                  <button key={index} className=' filter_buttons' onClick={() => { filterProjects(el.id) }}>
                    {
                      props?.selected_lang == "AL" ? el?.type_al :
                        props?.selected_lang == "EN" ? el?.type_en :
                          props?.selected_lang == "DE" ? el?.type_de :
                            el?.type_al
                    }

                  </button>
                )
                  :
                  ""
                }
              </div>
            </div>

          </div>
        </div>
        <div className="row mt-4">
          {/* {console.log('filteredData', filteredData)} */}
          {filteredData !== "" ?

            render()

            :
            renderAllPageData()
          }
        </div>
        <hr className='vijaNdarese col-12 mb-4 mt-2' />
      </div>

    </div>
  )
}
const mapStateToProps = state => {
  return {
    selected_lang: state.data.selected_lang,
    langData: state.data.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: () => dispatch(setLangData()),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurProjects);