// import { applyMiddleware } from "redux";
import { SET_SELECTED_LANG, SET_LANG_DATA, SET_PROJECTS_DATA } from "./types";
// import allLabels from "../../labels/all_labels.json"
// import allProjects from "../../DB/singlePageData.json"

const INITIAL_STATE = {
    selected_lang: "AL",
    langData: [],
    projectsData: []
};
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_LANG:
            return { ...state, selected_lang: action.data }
        case SET_LANG_DATA:
            return { ...state, langData: action.data }
        case SET_PROJECTS_DATA:
            return { ...state, projectsData: action.data }
        default: return state;
    }
};

export default reducer;