function Translations(langData, selected_lang, key ) {
    // console.log('perkthimetFunksioni', langData, selected_lang, key)
    if (selected_lang === 'AL') {
        let translate = langData?.filter(el => el.key === key)[0]?.content_al;
        // console.log('shqip', translate);
        return translate
    }
    if(selected_lang === 'EN') {
        let translate = langData?.filter(el => el.key === key)[0]?.content_en;
        // console.log('ENNNNN', translate);
        return translate
    }
    if(selected_lang === 'DE') {
        let translate = langData?.filter(el => el.key === key)[0]?.content_de;
        // console.log('deeee', translate);
        return translate
    }

}

export default Translations;