import React, { useEffect } from 'react'
import "./katallogjet.scss"
import PVCpdfShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet e PVC_FINAL_RGB.pdf"
import PVCpdfAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF PVC PRODUCTS_FINAL_RGB.pdf"
import PVCpdfGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_PVC.pdf"

import GlassPDFShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet nga XHAMI_FINAL_RGB.pdf"
import GlassPDFAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF GLASS_FINAL_RGB.pdf"
import GlassPDFGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_GLASS.pdf"

import AluminiumPDFShqip from "../../katallogjet/Website-PDF-Shqip/Katallogu_Produktet nga ALUMINI_2023 FINAL_RGB.pdf"
import AluminiumPDFAnglisht from "../../katallogjet/Website-PDF-English/CATALOGUE OF ALUMINIUM PRODUCTS_FINAL_RGB.pdf"
import AluminiumPDFGjermanisht from "../../katallogjet/Website-PDF-German/Katallogu German_ALUMINUM.pdf"

import alumin_img from "../../images/katalogjet/Katalogu_alumin.png"
import pvc_img from "../../images/katalogjet/Katalogu_pvc.png"
import glass_img from "../../images/katalogjet/Katalogu_Xhami.png"


import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';


const Katallogjet = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <div className="katallogjet_layout">
            <div className="container">
                <div className="row justify-cards">
                    <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                        <img className="card-img-top " style={{ maxWidth: "17rem" }} src={pvc_img} alt="Card image cap" />
                        <div className="card-body">
                            <p className="Card-Title">{Translations(props?.langData, props?.selected_lang, 'Katallogu-PVC-Label')}</p>
                            <div className="d-flex justify-content-center">
                                <a className='m-2' href={PVCpdfShqip} target="_blank">Shqip</a>
                                <a className='m-2' href={PVCpdfAnglisht} target="_blank">English</a>
                                <a className='m-2' href={PVCpdfGjermanisht} target="_blank">German</a>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                        <img className="card-img-top " style={{ maxWidth: "17rem" }} src={glass_img} alt="Card image cap" />
                        <div className="card-body">
                            <p className="Card-Title">{Translations(props?.langData, props?.selected_lang, 'Katallogu-Xhamit-Label')}</p>
                            <div className="d-flex justify-content-center">
                                <a className='m-2' href={GlassPDFShqip} target="_blank">Shqip</a>
                                <a className='m-2' href={GlassPDFAnglisht} target="_blank">English</a>
                                <a className='m-2' href={GlassPDFGjermanisht} target="_blank">German</a>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-5 single_katallog" style={{ maxWidth: "18rem" }}>
                        <img className="card-img-top " style={{ maxWidth: "17rem" }} src={alumin_img} alt="Card image cap" />
                        <div className="card-body">
                            <p className="Card-Title">{Translations(props?.langData, props?.selected_lang, 'Katallogu-Aluminit-Label')}</p>
                            <div className="d-flex justify-content-center">
                                <a className='m-2' href={AluminiumPDFShqip} target="_blank">Shqip</a>
                                <a className='m-2' href={AluminiumPDFAnglisht} target="_blank">English</a>
                                <a className='m-2' href={AluminiumPDFGjermanisht} target="_blank">German</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Katallogjet);


// export default Katallogjet