import React, { useEffect } from 'react'
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import "./certifikatat.scss"
import certifikatat from "../../DB/certifikatat.json"

import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';




const Certifikatat = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])


    const images = certifikatat
    console.log('imagesCertifikata', images)
    return (
        <div className="certifikatat_layout">
            <div className="container">
                <p className='certifikatat_title'>{Translations(props?.langData, props?.selected_lang, 'Certifikatat!-LABEL')}</p>
                <p className='certifikatat_text'>{Translations(props?.langData, props?.selected_lang, 'Shkrimi-tek-certifikatat-LABEL')}</p>
                <ImageGallery
                    items={images}
                    showPlayButton={false}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Certifikatat);



// export default Certifikatat