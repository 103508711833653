import React from 'react'

const HighMenagmentStaff = ({highManagement}) => {
    const AppApi = process.env.REACT_APP_API
console.log("highManagement dataaaa",highManagement)
    return (
        <div className="row pt-0 pt-md-5">
            {highManagement?.map((el) => {

                return (
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <img className='img-fluid staff_image' src={AppApi + el?.img_url} alt="" />
                        <p className='staff-name'  >{el?.staff_name}</p>
                        <p className='company_description ' >{el?.staff_position}</p>

                    </div>
                )
            })}
        </div>


    )
}

export default HighMenagmentStaff