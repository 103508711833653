import React, { useEffect, useState } from 'react'
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import './pergjegjsiaSociale.scss';
import aboutUsCompanyImage from "../../images/aboutUs/Pergjegjesia-sociale.png"
import kfShkendija from "../../images/sponzorizime/KF-Shkendija---Hajvali.png"
import "./pergjegjsiaSociale.scss"
import Translations from '../../components/translations/translations';
import axios from '../../axios';

const PergjegjsiaSociale = (props) => {


    const [ourPartners, setOurPartners] = useState([])


    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <div className="PergjegjsiaSociale_layout">
            <img className="img-fluid about_us_company_image w-100" src={aboutUsCompanyImage} />
            <div className="container">
                <div className="about_us_content">
                    <p className='about_us_page_title mt-5'>{Translations(props?.langData, props?.selected_lang, 'pergjegjsiaSocialeLabel')}</p>
                    <p className='company_description mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'PergjegjsiaSocialeTekstiLabel')}` }}></p>
                    <ul>
                        <li
                        >{Translations(props?.langData, props?.selected_lang, 'Pika-1-pergjegjsiaSociale')}</li>
                        <li
                        >{Translations(props?.langData, props?.selected_lang, 'Pika-2-pergjegjsiaSociale')}</li>
                        <li
                        >{Translations(props?.langData, props?.selected_lang, 'Pika-3-pergjegjsiaSociale')}</li>
                        <li
                        >{Translations(props?.langData, props?.selected_lang, 'Pika-4-pergjegjsiaSociale')}</li>
                    </ul>
                    <p className='company_description mt-5 mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Pika-5-pergjegjsiaSociale')}` }}></p>
                </div>
                <div className="sponzorizime">
                    <div className="row mt-4 mb-2" style={{ justifyContent: "space-around" }}>
                        <img className="col-sm-3 col-md-4 col-12 " src={kfShkendija} />
                        <div className="col-md-7 col-12 single_product_layout">
                            <h1 className='SocialResponsibility_title'>{Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-label')}</h1>
                            <p className='SocialResponsibility_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-description')}` }}>

                            </p>
                            <a href="https://www.facebook.com/KFShkendijaHajvali/" target='_blank' className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'vizitoFaqen-Label')}</a>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-between mt-4 mb-2">
                        <div className="col-md-7 col-12 single_product_layout">
                            <h1 className='single_product_title'>{Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-label')}</h1>
                            <p className='single_product_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'M-Technologie-si-sponsor-i-klubit-KF-SHKËNDIJA-description')}` }}>

                            </p>
                            <a href="https://www.facebook.com/KFShkendijaHajvali/" target='_blank' className='single_product_button'>{Translations(props?.langData, props?.selected_lang, 'vizitoFaqen-Label')}</a>
                        </div>
                        <img className="col-sm-3 col-md-4 col-12 " src={kfShkendija} />
                    </div> */}
                </div>
                {/* <div className="mt-5 mb-5 partneretTane">
                      {ourPartners.map((el, index) => {
            return (
              <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
            )
          })}
                </div> */}
            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PergjegjsiaSociale);