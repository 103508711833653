import React from 'react'
import "./standardiXhamit.scss"
import kfShkendija from "../../images/sponzorizime/KF-Shkendija---Hajvali.png"
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';
import standardiPerputshmeriseMocUp from "../../images/standardiPerputhshmerise/Standardi-i-perputheshmeris.png"
import standardiPerputshmerisePDF from "../../katallogjet/Website-PDF-Shqip/STANDARDI_I_PËRPUTHSHMËRISË_TË_XHAMIT.pdf"
import standardiPerputshmerisePDF_EN from "../../katallogjet/Website-PDF-English/STANDARDI_I_PËRPUTHSHMËRISË_TË_XHAMIT_ENGLISH.pdf"
import standardiPerputshmerisePDF_DE from "../../katallogjet/Website-PDF-German/STANDARDI_I_PËRPUTHSHMËRISË_TË_XHAMIT_GERMAN.pdf"

const StandardiXhamit = (props) => {
  return (
    <div className="standardi-xhamit-layout container">
      <div className="row justify-cards mb-2">
        <div className="card mb-5 single_standard mx-auto" style={{ maxWidth: "18rem" }}>
          <img className="card-img-top " style={{ maxWidth: "17rem" }} src={standardiPerputshmeriseMocUp} alt="Card image cap" />
          <div className="card-body">
            <p className="Card-Title">{Translations(props?.langData, props?.selected_lang, 'Katallogu-Xhamit-Label')}</p>
            <div className="d-flex justify-content-center">
              <a className='m-2' href={standardiPerputshmerisePDF} target="_blank">Shqip</a>
              <a className='m-2' href={standardiPerputshmerisePDF_EN} target="_blank">English</a>
              <a className='m-2' href={standardiPerputshmerisePDF_DE} target="_blank">German</a>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-12 single_product_layout">
          <h1 className='standardi-xhamit_title'>{Translations(props?.langData, props?.selected_lang, 'Standardi-i-përputhshmërisë-Label')}</h1>
          <p className='standardi-xhamit_description_main_page' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Standardi-Xhamit-description')}` }}></p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selected_lang: state.data.selected_lang,
    langData: state.data.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: (labels) => dispatch(setLangData(labels)),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardiXhamit);