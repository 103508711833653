import React, { useState, useEffect } from 'react';
import LatestNews from '../../../components/latestNews/latestNews';
import axios from "../../../axios";
import { useParams } from 'react-router-dom';

const SingleNews = () => {
  const [news, setNews] = useState([]);
  const { id } = useParams();
  const [thisNews, setThisNews] = useState({});
  const AppApi = process.env.REACT_APP_API;

  useEffect(() => {
    function getLatestNews() {
      axios.get("/api/admin/news/latest")
        .then((res) => setNews(res.data))
        .catch((err) => console.error('error fetching news', err));
    }

    function getThisNews() {
      axios.get(`/api/admin/news/singleNews/${id}`)
        .then((res) => setThisNews(res.data))
        .catch((err) => console.error('error fetching news', err));
    }

    getThisNews();
    getLatestNews();
  }, [id]);

  useEffect(() => {
    if (thisNews?.news_text) {


      const applyStyles = () => {
        const descTextElements = document.querySelectorAll('.desc_text');
        const descHeadingElements = document.querySelectorAll('.desc_heading');

        descTextElements.forEach(element => {
          element.style.fontFamily = "Roboto-Light";
          element.style.lineHeight = "26px";
          element.style.color = "#232d58";
          element.style.fontSize = "16px";
          element.style.fontWeight = 300;
        });

        descHeadingElements.forEach(element => {
          element.style.fontFamily = "RobotoCondensedBold";
          element.style.color = "#232d58";
          element.style.fontSize = "20px";
          element.style.fontWeight = 700;
          element.style.lineHeight = "16px";
        });
      };

      applyStyles();
    }

  }, );


  const inputDateStr = thisNews?.date;
  const date = new Date(inputDateStr);
  const day = date?.getDate();
  const month = date?.getMonth() + 1;
  const year = date?.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;

  return (
    <div className="container">
      <div className="single_news_layout">
        <p className='news_title'>{thisNews?.title?.slice(0, 1)?.toUpperCase() + thisNews?.title?.slice(1)?.toLowerCase()}</p>
        <p className='news_date'>{formattedDate}</p>
        <img className='img-fluid mb-5' src={AppApi + thisNews?.img_url} alt="" style={{ objectFit: "cover", width: "100%" }} />
        <div dangerouslySetInnerHTML={{ __html: thisNews?.news_text }} />
      </div>
      <LatestNews />
    </div>
  );
}

export default SingleNews;
