import { SET_SELECTED_LANG, SET_LANG_DATA, SET_PROJECTS_DATA } from "./types";



export const setSelectedLang = (data) => {
    return {
        type: SET_SELECTED_LANG,
        data: data
    }
}
export const setProjectsData = (data) => {
    return {
        type: SET_PROJECTS_DATA,
        data: data
    }
}
export const setLangData = (data) => {
    return {
        type: SET_LANG_DATA,
        data: data
    }
}

