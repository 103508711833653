import React, { useEffect } from 'react'
import "./footer.scss"
import FooterIMG from "../../images/Fabrika-e-re_jashte_Final.png"
import Translations from '../translations/translations';
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';


const Footer = (props) => {
    return (
        <footer>
            <div className="footer">
                <div className="footer_img">
                    <div className="gradienti"></div>
                    <img src={FooterIMG} alt="" />
                </div>
                <div className="footer_layout_info">
                    <div className="container">
                        <hr className='vija_ndarese_footer' />
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <div className="footer_box">
                                    <div className="footer_info_title">
                                        <Link to="/aboutUs" onClick={window.scrollTo(0, 0)} className="footer_info_title">{Translations(props?.langData, props?.selected_lang, 'AboutUs')}</Link>
                                    </div>
                                    <div className="footer_text">
                                        <Link to="/management" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'MenagjmentiLabel')}</Link>
                                        <Link to="/socialResponsebility">{Translations(props?.langData, props?.selected_lang, 'pergjegjsiaSocialeLabel')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer_box">
                                    <div className="footer_info_title">
                                        <Link className="footer_info_title" to="/products" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'whatWeOffer')}</Link>
                                    </div>
                                    <div className="footer_text">
                                        <Link to="products/alumin" onClick={window.location.reload}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaAlumini')}</Link >
                                        <Link to="products/PVC" onClick={window.location.reload}>PVC</Link>
                                        <Link to="products/glass" onClick={window.location.reload}>{Translations(props?.langData, props?.selected_lang, 'produkteNgaXhami')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer_box">
                                    <div className="footer_info_title">
                                        <Link to="/forPublic" className="footer_info_title">{Translations(props?.langData, props?.selected_lang, 'forPublic')}</Link>
                                    </div>
                                    <div className="footer_text">
                                        <Link to="/katallogjet" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'KatalogjetLabel')}</Link>
                                        <Link to="/mirenjohjet" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'MirënjohjetLabel')}</Link>
                                        <Link to="/certifikatat" onClick={window.scrollTo(0, 0)}>{Translations(props?.langData, props?.selected_lang, 'ÇertifikatatLabel')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footer_box">
                                    <div className="footer_info_title">
                                        <Link className="footer_info_title">{Translations(props?.langData, props?.selected_lang, 'kontaktimi')}</Link>
                                    </div>
                                    <div className="footer_text">
                                        <Link to="tel:+38338400888" type='tel'><strong>+ 383 (0) 38 400 888</strong></Link>
                                        <Link to="mailto:shitja@m-technologie.com" type='email'>shitja@m-technologie.com</Link>
                                        <Link to="mailto:info@m-technologie.com" type='email'>info@m-technologie.com</Link>
                                        <div className="col-md-12 mt-2">
                                            <div className="d-flex">
                                                <a href="https://www.facebook.com/mtechnologieofficial" target="_blank" style={{marginRight:"15px"}} >
                                                    <i className="fa-brands fa-facebook-f" style={{fontSize:"20px"}}></i>
                                                </a>
                                                <a href="https://instagram.com/mtechnologie_shpk?igshid=YmMyMTA2M2Y=" target="_blank" style={{marginRight:"5px"}} >
                                                    <i className="fa-brands fa-instagram" style={{fontSize:"20px"}}></i>                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer_bottom">
                            <div className="row">
                                <div className="col-md-6 col-12 ">
                                    <div className="footer_copyright">
                                    ©{new Date().getFullYear()} m-technologie.com
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 ">
                                    <div className="privacy ">
                                        <a role="button">Privacy Policy</a>
                                        <span className="line_seperator" />
                                        <a role="button">Sitemap</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
