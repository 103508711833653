import React from 'react'
import "./singleProduct.scss"
import { useEffect } from 'react';
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';
import imazhet from "../../DB/produktet.json"
import PvcPDF from "../../katallogjet/Katallogu_Produktet_PVC.pdf"
import GlassPDF from "../../katallogjet/Katallogu_Produktet_XHAMI.pdf"
import AluminiumPDF from "../../katallogjet/Katallogu_Produktet_ALUMINI.pdf"
import Slider from "react-slick";
import { useState } from 'react';
import singleProductGallery from "../../DB/pvcGallery.json"
import { useLocation } from 'react-router-dom'
import axios from '../../axios';

const SingleProduct = (props) => {

    const AppApi = `https://api.m-technologie.com/`



    let productType = useLocation().pathname.split("/").pop();

    const [ourPartners, setOurPartners] = useState([])

    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const [imageIndex, setImageIndex] = useState(1)

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    var slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) =>
            setImageIndex(current + 1),
        // this.setImageIndex({ oldSlide: current, activeSlide: next }),
        afterChange: current => setImageIndex(current + 1)
        // this.setImageIndex({ activeSlide2: current })

    };

    return (
        <>
            {productType == "PVC" ?
                <div className='single_product_layout'>
                    <div className="banner_layout">
                        <img src={imazhet?.pvcBanner} className="single_product_image_banner img-fluid" alt="..." />
                        <div className="container banner_description">
                            <p className='col'>{Translations(props?.langData, props?.selected_lang, 'QëndrueshmëriElartëDheMirëmbajtjeElehtë')}</p>
                        </div>
                    </div>
                    <div className="container single_product_description">
                        <div className="row">
                            <div className="col-md-8">
                                <p className="single_product_title">{Translations(props?.langData, props?.selected_lang, 'ProduktetNgaPVC')}</p>
                                <p className="product_text" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'mekanizmaTeProdhimitPVC')}` }} />
                            </div>
                            <div className="col-md-4">
                                <img src={imazhet?.pvcImage} className="img-fluid col-12" alt="" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'TeknologjiaEprodhimit')}</p>
                            <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiTeknologjiveTeProdhimitPVC')}` }} /> */}
                            {/* <img src={imazhet?.llojetEdritareve} className="img-fluid col-md-12" alt="" /> */}
                            {/* <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'MekanizmiLabel')}</p>
                            <p className="product_text col-md-12 pb-1" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'mekanizmaTeProdhimitPVC')}` }} /> */}
                            <div className="row  justify-content-around mt-5 pb-5">
                                <div className="col-md-4">
                                    <img src={imazhet?.imazhiRoletave} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'RolentetROLAPLUS')}</p>
                                    <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC')}` }} />
                                    <ul>
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li1')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li2')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li3')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li4')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li5')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li6')}` }} />
                                        <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li7')}` }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="galleria pt-5 pb-5  ">
                        <div className="container">
                            <div className="row">
                                <p className='galleria_title col-6'>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.PVC?.length}</p>
                            </div>
                            <Slider {...slickSettings}>
                                {singleProductGallery[0]?.PVC?.map((el) => {
                                    return (
                                        <>
                                            <img src={el.img_src}
                                                alt={el?.img_alt}
                                                className="single_project_slider"
                                            />
                                        </>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    <div className="container mt-5 mb-5 partneretTane">
                        {ourPartners?.map((el, index) => {
                            return (
                                <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                            )
                        })}
                    </div>
                </div >
                :
                productType == "alumin" ?
                    <div className='single_product_layout'>
                        <div className="banner_layout">
                            <img src={imazhet?.aluminBanner} className="single_product_image_banner img-fluid" alt="..." />
                            <div className="container banner_description">
                                <p className='col ' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdjehuKomodMeProduktetTonaKualitative')}` }}></p>
                            </div>
                        </div>
                        <div className="container single_product_description">
                            <div className="row justify-content-around">
                                <div className="col-md-8">
                                    <p className="single_product_title " dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetNgaAlumini')}` }}></p>
                                    <p className="product_text" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiPareProduktitAlumini')}` }} />
                                </div>
                                <div className="col-md-4">
                                    <img src={imazhet?.aluminImage1} className="img-fluid col-10" alt="" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'TitulliDyerDheDritareProduktitAlumini')}</p>
                                <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'pershkrimiDyerDheDritareProduktitAlumini')}` }} />

                                <div className="row  justify-content-around mt-5 pb-5">
                                    <div className="col-md-4">
                                        <img src={imazhet?.aluminImage2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'FasadaNgaAluminiTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'fasadaNgaAluminiDescription')}` }} />
                                        <img src={imazhet?.SpiderFasade} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'spiderFasadaTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'spiderFasadaDescription')}` }} />
                                        <img src={imazhet?.fasadeventiluar} className="img-fluid w-50 mb-3" alt="" />

                                        <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'fasadEventiluarTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'fasadEventiluarDescription')}` }} />
                                        <img src={imazhet?.fasadengapaneletkompozite} className="img-fluid w-50 mb-3" alt="" />

                                        <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'FasadaNgaPaneleKompoziteAluminTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadaNgaPaneleKompoziteAluminDescription')}` }} />
                                        <img src={imazhet?.fasadeguri} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'FasadëNgaGuriTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadëNgaGuriDescription')}` }} />
                                        <img src={imazhet?.fasadengaqeramika} className="img-fluid w-50 mb-3" alt="" />
                                        <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'FasadëNgaQeramikaTitle')}</p>
                                        <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'FasadëNgaQeramikaDescription')}` }} />
                                        <hr className='vijaNdarese col-12 mb-4 mt-4' />
                                        <a href={AluminiumPDF} target="_blank" className='openPDFparagraph'><i className='fa-regular fa-file-pdf' /> {Translations(props?.langData, props?.selected_lang, 'shikoKatalogunEprodukteveAluminSinglePage')}</a>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="galleria pt-5 pb-5  ">

                            <div className="container-fluid">
                                <div className="row">
                                    <p className='galleria_title col-6'>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                    <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.alumin?.length}</p>
                                </div>
                                <Slider {...slickSettings}>
                                    {singleProductGallery[0]?.alumin?.map((el) => {
                                        return (
                                            <>
                                                <img src={el.img_src}
                                                    alt={el?.img_alt}
                                                    className="single_project_slider"
                                                />
                                            </>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                        <div className="container mt-5 mb-5 partneretTane">
                            {ourPartners?.map((el, index) => {
                                return (
                                    <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                                )
                            })}
                        </div>
                    </div>
                    :
                    productType == "glass" ?
                        <div className='single_product_layout'>
                            <div className="banner_layout">
                                <img src={imazhet?.glassBannerImage} className="single_product_image_banner " alt="..." />
                                <div className="container banner_description">
                                    <p className='col ' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdjehuKomodMeProduktetTonaKualitative')}` }} />
                                </div>
                            </div>
                            <div className="container single_product_description pt-5">
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="single_product_title">{Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitTitle')}</p>
                                        <p className="product_text" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ProduktetExhamitFirstDescription')}` }} />
                                    </div>
                                    <div className="col-md-3">
                                        <img src={imazhet?.smallGlassImage} className="img-fluid col-12" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'EksterierLabel')}</p>
                                            <ul>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'FASADAXHAMISpiderLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'FASADËDHELLANTERINËSPIDERLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'MULESËÇATIE(STREHË)Label')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'STREHËDHEMBËSHTETJENGAPOSHTË')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'STREHËDHEMBËSHTETJENGALARTËMELITAR')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËEksterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËTERASEEksterierLable')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËMEPULTEksterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËMERAMJEEksterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'RRETHOJËNDARËSEEksterieLabel')}</li>
                                            </ul>
                                            <img src={imazhet?.Eksterier} className="img-fluid w-50 mb-3" alt="" />

                                        </div>
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'EnterierLabel')}</p>
                                            <ul>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'DYEREnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'NDARJETËHAPËSIRAVEEnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'DUSHKABINAEnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'MOBILIERIKUZHINAEnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'TAVOLINËDOLLAPEETJEnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'XHAMPËRKABINAEnterierLabel')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'XHAMPËRASHENSORËEnterierLabel')}</li>
                                            </ul>
                                            <img src={imazhet?.Enterier} className="img-fluid w-75 mb-3" alt="" />

                                        </div>
                                        <div className="col-md-4">
                                            <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'DekorativeLabel')}</p>
                                            <ul>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'KREATIVREZINAdekorative')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'XHAMMENGJYRËdekorative')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'PRINTIMDIGJITALdekorative')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'Fasadëxhami(spider)dekorative')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'Mbulesëçatie(strehë)dekorative')}</li>
                                                <li className='product_text'>{Translations(props?.langData, props?.selected_lang, 'Rrethojëdekorative')}</li>
                                            </ul>
                                            <br />
                                            <img src={imazhet?.Dekorative} className="img-fluid w-75 mb-3" alt="" />

                                        </div>
                                    </div>
                                    <p className="single_product_smaller_title">{Translations(props?.langData, props?.selected_lang, 'NdarjetëbrendshmeLabel')}</p>
                                    <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'NdarjetëbrendshmeDescriptionText')}` }} />
                                    <div className="row  justify-content-around mt-5 pb-5">
                                        <div className="col-md-4">
                                            <img src={imazhet?.glassBathroom} className="img-fluid mt-3" alt="" />
                                        </div>
                                        <div className="col-md-6">
                                            <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'ZgjidhjeSofistikuarPërBanjonTënde')}</p>
                                            <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'ZgjidhjeSofistikuarPërBanjonTëndeTeksti')}` }} />
                                            <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'JepiNgjyrëAmbientitTuaj')}</p>
                                            <img src={imazhet?.jepiVenditNgjyre} className="img-fluid w-75 mb-3" alt="" />

                                            <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'JepiNgjyrëAmbientitTuajTeksti')}` }} />
                                            <p className="single_product_smaller_title mt-3">{Translations(props?.langData, props?.selected_lang, 'PrintimDigjitalnëXham')}</p>
                                            <img src={imazhet?.Printimdigjitalnëxham} className="img-fluid w-75 mb-3" alt="" />
                                            <p className="product_text col-md-12" dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'PrintimDigjitalnëXhamTeksti')}` }} />
                                            <hr className='vijaNdarese col-12 mb-4 mt-4' />
                                            <a href={GlassPDF} target="_blank" className='openPDFparagraph'><i className='fa-regular fa-file-pdf' /> {Translations(props?.langData, props?.selected_lang, 'shikoKatalogunEprodukteveXhamitSinglePage')}</a>
                                            {/* <ul>
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li1')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li2')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li3')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li4')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li5')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li6')}` }} />
                                                <li className='product_text' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'roletatTeProdhimitPVC-li7')}` }} />
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="galleria pt-5 pb-5  ">

                                <div className="container-fluid">
                                    <div className="row">
                                        <p className='galleria_title col-6'>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                                        <p className='galleria_image_number col-6'>{imageIndex}/{singleProductGallery[0]?.XHAM?.length}</p>
                                    </div>
                                    <Slider {...slickSettings}>
                                        {singleProductGallery[0]?.XHAM?.map((el) => {
                                            return (
                                                <>
                                                    <img src={el.img_src}
                                                        alt={el?.img_alt}
                                                        className="single_project_slider"
                                                    />
                                                </>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </div>
                            <div className="container mt-5 mb-5 partneretTane">
                                {ourPartners?.map((el, index) => {
                                    return (
                                        <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                                    )
                                })}
                            </div>
                        </div>
                        :
                        ""
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);