import React, { useState, } from 'react';
import "./singlepage.scss";
// import singlePageData from "../../DB/singlePageData.json";
import { Link, useLocation, useParams } from 'react-router-dom';
// import { Carousel } from '3d-react-carousal';
// import ourPartners from "../../DB/partneret.json"
import Slider from "react-slick";
import { useEffect } from 'react';
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Translations from '../../components/translations/translations';
import axios from '../../axios';

const SinglePage = (props) => {

    let params = useParams()

    const [thisPageData, setThisPageData] = useState({})
    const [singlePageData, setSinglePageData] = useState([])

    const getAllPageData = () => {
        axios.get(`/api/client/homepage/all-projects`)
            .then((res) => {
                setSinglePageData(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    const getSinglePageData = () => {
        axios.get(`/api/client/homepage/single-project/${params?.id}/${params?.type}`)
            .then((res) => {
                setThisPageData(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }

    useEffect(() => {
        getAllPageData()
        getSinglePageData()
    }, [params])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    const [imageIndex, setImageIndex] = useState(1)

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }



    let type = useParams('type')

    const pageData = singlePageData?.filter((el) => {
        return el?.business_type_id == type.type
    });

    // console.log('pagedata1233', singlePageData?.filter((el) => el?.))
    // el?.lloji_biznesit.split('*')[0] == type.type;
    // let url = window.location.pathname.split("/").slice(2,5);
    // let finalURL = "/"+url[0]+"/"+url[1]+"/"+url[2];
    // console.log('thisPageData', url[0]+"/"+url[1]+"/"+url[2])
    // let thisPageData = singlePageData?.find((el) => (el.pageUniqueURL == window.location.pathname))
    // console.log('thisPageData', window.location.pathname)

    // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        document.getElementById('scrollHere').scrollIntoView();
    };




    const AppApi = `https://api.m-technologie.com/`
    const [ourPartners, setOurPartners] = useState([])
    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])

    const render = () => {
        const itemsPerPage = 6;
        const offset = currentPage * itemsPerPage;


        const filteredPageData = pageData.slice(offset, offset + itemsPerPage);
        console.log("filteredPageData", filteredPageData)
        return (
            <>
                {filteredPageData?.map((el) => {
                    return (
                        <Link className="col-md-6 col-12 singleCard" to={`/project/${el?.page_unique_url}`}>
                            <div className='related_projects_image' style={{ backgroundImage: `url(${AppApi + el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} />
                            <h5 className="cardTitle">{
                                props?.selected_lang == "AL" ? el?.obiject_name_al :
                                    props?.selected_lang == "EN" ? el?.obiject_name_en :
                                        props?.selected_lang == "DE" ? el?.obiject_name_de :
                                            el?.obiject_name_al
                            }</h5>
                            <p className="cardText mb-3">
                                <i className="fas fa-map-marker-alt mb-5" aria-hidden="true" /> &nbsp;
                                {
                                    props?.selected_lang == "AL" ? el?.location_al :
                                        props?.selected_lang == "EN" ? el?.location_en :
                                            props?.selected_lang == "DE" ? el?.location_de :
                                                el?.location_al
                                }
                            </p>
                        </Link>
                    )
                })}
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(pageData?.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
                {console.log('singlePageData??.length / pageData', pageData?.length / itemsPerPage)}
            </>);
    };
    // -------------------- FILTERED PAGE DATA PAGINATION ---------------------\\


    // -------------------- ALL PAGE DATA PAGINATION ---------------------\\
    const [allPageDataCurrentPage, setAllPageDataCurrentPage] = useState(0);

    const handleAllPageDataClick = (data) => {
        setAllPageDataCurrentPage(data.selected);
        document.getElementById('scrollHere').scrollIntoView();
    };

    const renderAllPageData = () => {
        const AllitemsPerPage = 6;
        const offset = allPageDataCurrentPage * AllitemsPerPage;

        const allPageData = singlePageData.slice(offset, offset + AllitemsPerPage);
        console.log('singlepagedata', singlePageData?.length)

        return (
            <>
                {allPageData?.map((el) => {
                    console.log('elgasih', el.pageUniqueURL)

                    return (
                        <Link className="col-md-6 singleCard" to={`/project/${el?.page_unique_url}`}
                        >
                            <div className='related_projects_image' style={{ backgroundImage: `url(${AppApi + el?.bg_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: "415px" }} />

                            <h5 className="cardTitle"> {
                                props?.selected_lang == "AL" ? el?.obiject_name_al :
                                    props?.selected_lang == "EN" ? el?.obiject_name_en :
                                        props?.selected_lang == "DE" ? el?.obiject_name_de :
                                            el?.obiject_name_al
                            }</h5>
                            <p className="cardText mb-3">
                                <i className="fas fa-map-marker-alt mb-5" aria-hidden="true" /> &nbsp;
                                {
                                    props?.selected_lang == "AL" ? el?.location_al :
                                        props?.selected_lang == "EN" ? el?.location_en :
                                            props?.selected_lang == "DE" ? el?.location_de :
                                                el?.location_al
                                }
                            </p>
                        </Link>
                    )
                })}
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(singlePageData?.length / AllitemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleAllPageDataClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
                {console.log('singlePageData??.length / AllitemsPerPage', Math.ceil(singlePageData?.length / AllitemsPerPage))}
            </>);
    };

    // -------------------- ALL PAGE DATA PAGINATION ---------------------\\


    // Slick Slider Settings
    var slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) =>
            setImageIndex(current + 1),
        // this.setImageIndex({ oldSlide: current, activeSlide: next }),
        afterChange: current => setImageIndex(current + 1)
        // this.setImageIndex({ activeSlide2: current })

    };


    const style = {
        backgroundImage: `url(${AppApi + thisPageData?.bg_img})`
    }
    const style1 = {
        backgroundPosition: "4%",
        backgroundImage: `url(${AppApi + thisPageData?.bg_img})`
    }
    const pathi = useLocation();
    const test = pathi.pathname == "/project/prishtinaMall/3" ? style1 : style



    return (
        <div className='SinglePage'>
            <div className="col-12 fotografiaProjektit img-fluid"
                style={test}
            >
                <div className="background_text_shadow">
                    <h5 className="emriProjektit">
                        {
                            props?.selected_lang == "AL" ? thisPageData?.obiject_name_al :
                                props?.selected_lang == "EN" ? thisPageData?.obiject_name_en :
                                    props?.selected_lang == "DE" ? thisPageData?.obiject_name_de :
                                        thisPageData?.obiject_name_al
                        }
                    </h5>
                    <p className="lokacioniProjektit mb-3">
                        <i className="fas fa-map-marker-alt mb-5" aria-hidden="true" /> &nbsp;
                        {
                            props?.selected_lang == "AL" ? thisPageData?.location_al :
                                props?.selected_lang == "EN" ? thisPageData?.location_en :
                                    props?.selected_lang == "DE" ? thisPageData?.location_de :
                                        thisPageData?.location_al
                        }
                    </p>
                </div>
            </div>
            <div className="pershkrimiProjektit">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className=" col-md-3">
                            <div className="vija_ndarse_statuseve">
                                <div className=" statuset">
                                    <p className='emriPershkrimit'>
                                        {Translations(props?.langData, props?.selected_lang, 'LLOJIIBIZNESITlabel')}
                                    </p>
                                    <p className='statusiNgaJSON'>
                                        {
                                            props?.selected_lang == "AL" ? thisPageData?.businessType?.type_al :
                                                props?.selected_lang == "EN" ? thisPageData?.businessType?.type_en :
                                                    props?.selected_lang == "DE" ? thisPageData?.businessType?.type_de :
                                                        thisPageData?.businessType?.type_al
                                        }
                                    </p>
                                </div>
                                <div className="vija_ndarese" />
                            </div>
                        </div>
                        <div className=" col-md-3">
                            <div className="vija_ndarse_statuseve">
                                <div className=" statuset">
                                    <p className='emriPershkrimit'>{Translations(props?.langData, props?.selected_lang, 'OBJECTLabel')}</p>
                                    <p className='statusiNgaJSON'>
                                        {
                                            props?.selected_lang == "AL" ? thisPageData?.obiject_name_al :
                                                props?.selected_lang == "EN" ? thisPageData?.obiject_name_en :
                                                    props?.selected_lang == "DE" ? thisPageData?.obiject_name_de :
                                                        thisPageData?.obiject_name_al
                                        }
                                    </p>
                                </div>
                                <div className="vija_ndarese" />
                            </div>
                        </div>
                        <div className=" col-md-3">
                            <div className="vija_ndarse_statuseve">
                                <div className=" statuset">
                                    <p className='emriPershkrimit'>{Translations(props?.langData, props?.selected_lang, 'STATUSlabel')}</p>
                                    <p className='statusiNgaJSON'>
                                        {
                                            props?.selected_lang == "AL" ? thisPageData?.project_status?.status_al :
                                                props?.selected_lang == "EN" ? thisPageData?.project_status?.status_en :
                                                    props?.selected_lang == "DE" ? thisPageData?.project_status?.status_de :
                                                        thisPageData?.project_status?.status_al
                                        }
                                    </p>
                                </div>
                                <div className="vija_ndarese" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="kontentiProjektit">
                <div className="container">
                    <div className="rreshtimi">
                        <img className="col-md-5 col-12 imazhi"
                            src={`${AppApi + thisPageData?.project_content?.content_img}`}

                        />

                        <div className="col-md-5 col-12 pershkrimiImazhit">
                            <div className="detajetEprojektit">
                                <h1 className='titulli'>{Translations(props?.langData, props?.selected_lang, 'DetajetEProjektitPerkthim')}</h1>
                                <p className='tekstiSinglepage'
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                        ${props?.selected_lang == "AL" ? thisPageData?.project_content?.project_details_al :
                                                props?.selected_lang == "EN" ? thisPageData?.project_content?.project_details_en :
                                                    props?.selected_lang == "DE" ? thisPageData?.project_content?.project_details_de :
                                                        thisPageData?.project_content?.project_details_al
                                            }
                                    ` }}
                                >

                                </p>
                            </div>
                            <div className="materialet">
                                <h1 className='titulli'>{Translations(props?.langData, props?.selected_lang, 'MaterialetLabel')}</h1>
                                <ul className='listimiUL'>
                                    {thisPageData?.project_content?.content_materials.map((el) => {
                                        return <li>
                                            {
                                                props?.selected_lang == "AL" ? el?.material_al :
                                                    props?.selected_lang == "EN" ? el?.material_en :
                                                        props?.selected_lang == "DE" ? el?.material_de :
                                                            el?.material_al
                                            }
                                        </li>
                                    })

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="galleria pt-5 pb-5  ">

                <div className="container">
                    <div className="row">
                        <p className='galleria_title col-6'>{Translations(props?.langData, props?.selected_lang, 'GaleriaLabel')}</p>
                        {/* {console.log('thisPageData?.galleria', thisPageData?.galleria)} */}
                        <p className='galleria_image_number col-6'>{imageIndex}/{thisPageData?.project_gallery?.length}</p>
                    </div>
                    <Slider {...slickSettings}>
                        {thisPageData?.project_gallery?.map((el, index) => {
                            return (
                                <>
                                    <img src={AppApi + el?.img_url}
                                        alt={index}
                                        className="single_project_slider"
                                    />
                                </>
                            )
                        })}
                    </Slider>
                </div>
            </div>

            <div className="container mt-4 projektetErealizuara " id='scrollHere'>
                <div className="tekstiProjekteveTengjashme">
                    <div className="row">
                        <h1 className='titulliProjekteve col-md-6'> {pageData?.length > 1 ? `${Translations(props?.langData, props?.selected_lang, 'ProjektetEkategorisLabel')}:
                        
                        
                        ${props?.selected_lang == "AL" ? thisPageData?.businessType?.type_al :
                                props?.selected_lang == "EN" ? thisPageData?.businessType?.type_en :
                                    props?.selected_lang == "DE" ? thisPageData?.businessType?.type_de :
                                        thisPageData?.businessType?.type_al
                            }`

                            : `${Translations(props?.langData, props?.selected_lang, 'ProjektetErealizuaraLabel')}`}</h1>
                        <p className='numrimiProjekteve col-md-6'>{pageData?.length > 1 ? `${Translations(props?.langData, props?.selected_lang, 'ShowingLabel')}: ${pageData?.length} ${Translations(props?.langData, props?.selected_lang, 'ProjekteLabel')}` : `${Translations(props?.langData, props?.selected_lang, 'ShowingLabel')}: ${singlePageData?.length} ${Translations(props?.langData, props?.selected_lang, 'ProjekteLabel')}`}</p>
                    </div>
                </div>
                <hr className='vijaNdarese mt-3 col-12' />


                {/* <div className="tekstiProjekteveTengjashme">
                    <h1 className='titulliProjekteve'>{pageData?.length !== 0 ? `Projektet  te kategorisë: ${thisPageData?.lloji_biznesit}` : `Projektet e realizuara`}</h1>
                    <h3 className='numrimiProjekteve'>{pageData?.length !== 0 ? `Duke shfaqur: ${pageData??.length} projekte` : `Duke shfaqur: ${singlePageData??.length} projekte`}</h3>
                </div> */}
                <div className="row ">
                    {console.log('pageData?.length', pageData?.length)}
                    {pageData?.length > 1 ?
                        render()
                        :
                        renderAllPageData()
                    }
                </div>
            </div>

            <div className=" container col-md-12 mt-2 mb-2 partneretTane">
                {ourPartners?.map((el, index) => {
                    return (
                        <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                    )
                })}
            </div>
        </div >
    )

}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: () => dispatch(setLangData()),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang)),
        setProjectsData: (projects) => dispatch(setSelectedLang(projects))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePage);