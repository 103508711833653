import React from 'react';

// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
// REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, presisted } from './redux/store';
// import { injectStore } from './axios';
// injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

//  DISABLE DEV TOOLS WITH KEYBOARD SHORT CUTS

document.addEventListener('contextmenu', (e) => e.preventDefault());

document.onkeydown = function (e) {
  if (e.key == 123) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.code == 'KeyI'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.code == 'KeyC'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.code == 'KeyJ'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == 'F12'.charCodeAt(0)) {
    return false;
  }
  if (e.key == 'F12') {
    return false;
  }
  if (e.ctrlKey && e.keyCode == '85') {
    return false;
  }
}
root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={presisted}>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>,
);