import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import m_technologie from './Functions/reducer';
import storage from "redux-persist/lib/storage"

// import rootReducer from './rootReducer';
const persistConfig = {
    key: "m-technologie",
    storage,
}

const rootReducer = combineReducers({ data: m_technologie });

export const store = createStore(
    persistReducer(persistConfig, rootReducer),
    // composeWithDevTools(),
);


export const presisted = persistStore(store);