import React from 'react'
import './contactUs.scss'

import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import Translations from '../../components/translations/translations';


const ContactUs = (props) => {
  return (
    <div className="contact_layout">
      <div className="container">
        <div className="row data">
          <div className="harta col-md-7">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2930.2013991046765!2d21.1389088!3d42.741791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354a055b3904d19%3A0x6b8cdacb1fdfd54f!2sM-Technologie!5e0!3m2!1sen!2s!4v1676252285957!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="informatat col-md-5">
            <p className="contact_title">{Translations(props?.langData, props?.selected_lang, 'Na-Kontaktoni-LABEL')}</p>
            <div className="kontakt_info">
              <div className='d-flex'>
                <i className='fa fa-phone ' /><p>{Translations(props?.langData, props?.selected_lang, 'Telefoni-LABEL')}</p> &nbsp;  <a href="tel:+38338400888" type='tel'><strong>+ 383 (0) 38 400 888</strong></a>
              </div>
              <div className='d-flex'>
                <i className='fa fa-phone' />
                <p>{Translations(props?.langData, props?.selected_lang, 'Telefoni-LABEL')}</p> &nbsp;<a href="tel:+38349777075" type='tel'><strong>+ 383 (0) 49 777 075</strong></a>
              </div>
              <div >
                <i className='fa fa-inbox' /><a href="mailto:shitja@m-technologie.com" type='email'>shitja@m-technologie.com</a>
              </div>
              <div  className='mt-2'>
                <i className='fa fa-inbox' /><a href="mailto:info@m-technologie.com" type='email'>info@m-technologie.com</a>
              </div>
            </div>
            <p className='welcoming_pharagraph ' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Contact-Us-Page-Text-Label')}` }}></p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selected_lang: state.data.selected_lang,
    langData: state.data.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: (labels) => dispatch(setLangData(labels)),
    setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);


// export default ContactUs;