import { useEffect, React, useState } from 'react'
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import './ourStaff.scss';
import aboutUsCompanyImage from "../../images/aboutUs/about_us_banner.png"
import insideCompanyImage from "../../images/aboutUs/inside_company_image.png"
import Translations from '../../components/translations/translations';
import axios from '../../axios';
import HighMenagmentStaff from '../../components/highMenagmentStaff/highMenagmentStaff';
const OurStaff = (props) => {
    // const AppApi = process.env.REACT_APP_API

    const [ourStaff, setOurStaff] = useState([])
    console.log("ourStaff", ourStaff)

    function handleGetPartners() {
        axios.get("/api/admin/our_staff/all")
            .then(res => {
                setOurStaff(res.data)
            })
            .catch(err => {
                // toast.error(err)
                console.log('error getting partners ', err)
            })
    }
    useEffect(() => {
        handleGetPartners()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])

    return (
        <div className="our_staff_layout">
            <img className="img-fluid our_staff_company_image" src={aboutUsCompanyImage} />
            <div className="container">
                <div className="our_staff_content">
                    <p className='our_staff_page_title mt-5' dangerouslySetInnerHTML={{ __html: `Menaxhmenti i lartë i kompanisë M-Technologie` }} />
                    <hr className='vizaNdarese ' />
                    {ourStaff && <HighMenagmentStaff highManagement={ourStaff?.filter((elm) => elm?.is_high_management == true)} />}
                </div>
                <div className="our_staff_content">
                    <p className='our_staff_page_title mt-5' dangerouslySetInnerHTML={{ __html: `Departamentet e Kompanisë M-Technologie` }} />
                    <hr className='vizaNdarese ' />
                    <div className="row pt-5">
                        {ourStaff && <HighMenagmentStaff highManagement={ourStaff?.filter((elm) => elm?.is_high_management === false)} />}

                        {/* {ourStaff?.filter((elm) => elm?.is_high_management == false)?.map((el) => {
                            return (
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                                    <img className='img-fluid staff_image' src={AppApi + el?.img_url} alt="" />
                                    <p className='staff-name'  >{el?.staff_name}</p>
                                    <p className='company_description ' >{el?.staff_position}</p>

                                </div>
                            )
                        })} */}
                    </div>

                </div>
                {/* <div className="mt-5 mb-5 partneretTane">
                    {ourPartners?.map((el, index) => {
                        return (
                            <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                        )
                    })}
                </div> */}
            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurStaff);


