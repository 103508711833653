import React, { useEffect, useState } from 'react'


import './b2b.scss';
import aboutUsCompanyImage from "../../images/b2b_m-technologie.jpg"
import Translations from '../../components/translations/translations';
import { setLangData, setSelectedLang } from "../../redux/Functions/actions";
import { connect } from 'react-redux';
import axios from '../../axios';
import { toast } from 'react-toastify'


const B2b = (props) => {

    const AppApi = `https://api.m-technologie.com/`

    const [ourPartners, setOurPartners] = useState([])


    const getOurPartners = () => {
        axios.get("/api/client/homepage/our-partners")
            .then((res) => {
                setOurPartners(res.data)
            })
            .catch((err) => {
                console.error('res homepage sliders', err)
            })
    }
    useEffect(() => {
        getOurPartners()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.reload])
    const contact = { name_surname: "", address: "", message: "", email: "", phone_number: "" };

    const handleCostumer = (e, dataType) => {
        // e.preventDefault();
        let currentState = contact;
        if (dataType === 'nameSurname') {
            let assignEmriMbiemri = Object.assign(currentState);
            assignEmriMbiemri.name_surname = e.target.value;
        }
        if (dataType === 'address') {
            let assignAddress = Object.assign(currentState);
            assignAddress.address = e.target.value;
        }
        if (dataType === 'Message') {
            let assignMessage = Object.assign(currentState);
            assignMessage.message = e.target.value;
        }
        if (dataType === 'phone') {
            let assignPhoneNumber = Object.assign(currentState);
            assignPhoneNumber.phone_number = e.target.value;
        }
        if (dataType === 'email') {
            let assignEmail = Object.assign(currentState);
            assignEmail.email = e.target.value;
        }
        console.log('COSTUMER', contact)
    };
    const submitData = async (e) => {
        e.preventDefault();
        let data = {
            costumer: contact,
        };
        await axios
            .post("/api/general/contact", data)
            .then((res) => {
                // console.log('response', res)
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                toast.success('Do ju kontaktojmë sa më shpejtë!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('Kishte një problem gjatë dergimit te kerkeses, ju lutem provoni prap më vonë!');
            });
    }
    return (
        <div className="about_us_layout">
            <img className="img-fluid about_us_company_image" src={aboutUsCompanyImage} />
            <div className="container">
                <div className="about_us_content">
                    <p className='about_us_page_title mt-5'>{Translations(props?.langData, props?.selected_lang, 'B2B-Label')}</p>
                    <p className='company_description mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'B2B-Tekst')}` }}></p>
                </div>

                <div className="contsct_form col-12">
                    <p className='informativ_text_for_user mt-5 col-12'>{Translations(props?.langData, props?.selected_lang, 'Teksti-Plotsimit-te-dhenave-b2b-Label')}</p>
                    <p className='send_to_contact_you_text  mb-5' dangerouslySetInnerHTML={{ __html: `${Translations(props?.langData, props?.selected_lang, 'Dergo-Porosine-teksti-b2b-Label')}` }}></p>

                    <div className="personal_data_input_fields col-12 mt-4">
                        {/* <div className=" row"> */}
                        <div className=" col-md-4 mb-4 mx-auto  inputContent ">
                            <input onChange={(e) => { handleCostumer(e, "nameSurname") }} type="text" required placeholder={Translations(props?.langData, props?.selected_lang, 'Emri-dhe-mbiemri-konfiguratori-Label')} />
                            {/* <span className='name_and_surname '></span> */}

                        </div>
                        <div className=" col-md-4 mb-4 mx-auto  inputContent">
                            <input onChange={(e) => { handleCostumer(e, "email") }} type="email" required placeholder={Translations(props?.langData, props?.selected_lang, 'E-mail-konfiguratori-Label')} />
                            {/* <span className='name_and_surname '></span> */}

                        </div>
                        <div className=" col-md-4 mb-4 mx-auto  inputContent">
                            <input onChange={(e) => { handleCostumer(e, "phone") }} type="number" required placeholder={Translations(props?.langData, props?.selected_lang, 'Numri-telefonit-konfiguratori-Label')} />
                            {/* <span className='name_and_surname '></span> */}
                        </div>
                        <div className=" col-md-4 mb-4 mx-auto  inputContent">
                            <input onChange={(e) => { handleCostumer(e, "address") }} name="address" type="text" required placeholder={Translations(props?.langData, props?.selected_lang, 'Adresa-konfiguratori-Label')} />
                            {/* <span className='name_and_surname '></span> */}

                        </div>
                        <div className=" col-md-4 mb-4 mx-auto  inputContent">
                            <textarea className='inputContent-message' onChange={(e) => { handleCostumer(e, "Message") }} required placeholder={Translations(props?.langData, props?.selected_lang, 'mesazhi-label')} />
                            {/* <span className='name_and_surname '></span> */}

                        </div>
                        <div className="send_order_and_button">
                            <button className=' mb-5 send_this_order' onClick={submitData}> {Translations(props?.langData, props?.selected_lang, 'Dërgo-mesazhin-Label')} </button>
                        </div>
                        {/* </div> */}
                    </div>


                    <div className="mt-5 mb-5 partneretTane">
                        {ourPartners?.map((el, index) => {
                            return (
                                <img key={index} data-aos={index < 4 || (index >= 8 && index < 12) ? "fade-right" : "fade-left"} data-aos-duration="1000" src={AppApi + el?.img_url} alt={el.img_alt} />
                            )
                        })}
                    </div>
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        selected_lang: state.data.selected_lang,
        langData: state.data.langData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLangData: (labels) => dispatch(setLangData(labels)),
        setSelectedLang: (lang) => dispatch(setSelectedLang(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(B2b);